import { Container, Form, Header, Image, InputOnChangeData, Segment } from "semantic-ui-react";
import { ResponsiveContainer } from "../components/DefaultResponsiveContainer";
import { useCallback, useEffect, useState } from "react";
import MediaSlider from "../components/MediaSlider";
import { useGeneralContext } from "../storage/context/GeneralContext";
import { Footer } from "../components/Footer";
import { MAX_MEDIA_SIZE } from "../common/constant";
import { uploadFileToStorage } from "../api/firebase";
import { SimpleErrorMessage } from "../components/ErrorMessages";
import { updateProfileInfo } from "../api/profileManagement";

export function ProfileManagement() {
    const [modified, setModified] = useState(false);
    const [data, setData] = useState<any>({});
    const [profileImage, setProfileImage] = useState<File | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const { currentProfile, refreshProfile } = useGeneralContext();

    const onStart = useCallback(() => {
        setModified(() => false);
        setData(currentProfile);
        setProfileImage(undefined);
    }, [currentProfile, setData, setProfileImage]);

    useEffect(() => {
        onStart();
    }, [onStart]);

    useEffect(() => {
        if (currentProfile) {
            onStart();
        }
    }, [currentProfile, onStart]);

    const saveChanges = useCallback(async () => {
        // Save changes to the server
        let downloadURL;
        if (profileImage) {
            downloadURL = await uploadFileToStorage(profileImage, `profileImages/${data.uid}`);
        }
        const newData = { ...data, image: downloadURL || data.image };
        setLoading(() => true);
        updateProfileInfo(newData)
            .then(() => {
                refreshProfile();
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setLoading(() => false);
            });
    }, [data, profileImage, setError, refreshProfile]);

    const handleProfilePictureChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        // Handle profile picture upload
        if (e.target.files && e.target.files[0].size < MAX_MEDIA_SIZE) {
            setModified(() => true);
            const file = e.target.files[0];
            setProfileImage(file);
        }
    }, [setProfileImage, setModified]);

    return (
        <ResponsiveContainer>
            <Segment basic>
                <Header as="h1">Basic Information</Header>
                {error ? <SimpleErrorMessage error={error} /> : null}
                <Form
                    style={{ maxWidth: '80vw', margin: 'auto', textAlign: 'left' }}
                >
                    <Form.Group style={{ width: '100%' }}>
                        <Form.Input
                            label="First Name"
                            value={data?.firstName}
                            placeholder="First Name"
                            maxLength={50}
                            onChange={(e) => {
                                setModified(true);
                                setData((prev: any) => ({ ...prev, firstName: e.target.value }))
                            }}
                            width={'8'}
                        />
                        <Form.Input
                            label="Last Name"
                            value={data?.lastName}
                            placeholder="Last Name"
                            width={'8'}
                            maxLength={50}
                            onChange={(e) => {
                                setModified(true);
                                setData((prev: any) => ({ ...prev, lastName: e.target.value }))
                            }}
                        />
                    </Form.Group>
                    <Form.Input
                        label="Display Name"
                        value={data?.displayName}
                        placeholder="Display Name"
                        width={'16'}
                        maxLength={50}
                        onChange={(e) => {
                            setModified(true);
                            setData((prev: any) => ({ ...prev, displayName: e.target.value }))
                        }}
                    />
                    <Form.Group style={{ justifyContent: 'flex-end' }}>
                        <Container style={{ width: '90%' }}>
                            {data?.image
                                ? <Image src={data?.image} size="large" />
                                : <MediaSlider localFiles={profileImage ? [profileImage] : []} />}
                        </Container>
                        <Form.Input
                            label="Profile Image"
                            accept="image/*"
                            placeholder="Profile Image"
                            type="file"
                            width={'8'}
                            onChange={handleProfilePictureChange}
                        />
                    </Form.Group>
                    {modified ?
                        <Form.Group>
                            <Form.Button primary onClick={saveChanges} loading={loading} disabled={loading}>Save</Form.Button>
                            <Form.Button secondary onClick={onStart} disabled={loading}>Cancel</Form.Button>
                        </Form.Group>
                        : null}
                </Form>
            </Segment>
            <Container style={{ marginTop: 10, width: 0 }} />
            <Footer />
        </ResponsiveContainer>
    )
}