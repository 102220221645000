import { memo } from "react";
import { Card, Grid, Icon, Image, List, Segment } from "semantic-ui-react";
import { useGeneralContext } from "../storage/context/GeneralContext";
import { useNavigate } from "react-router-dom";
import { missingProfileImage } from "../common/constant";

function ProfileContainer() {
    const { currentProfile } = useGeneralContext();
    const image = (currentProfile.image) || missingProfileImage;
    return (
        <Card style={{ width: '100%' }}>
            <Card.Content style={{ borderWidth: 0, backgroundColor: 'white' }}>
                <Image
                    floated="left"
                    size="mini"
                    src={image}
                />
                <a href="/myprofile" className="cursor-pointer" style={{ color: 'black', fontSize: 16, fontWeight: 'bold' }}>{currentProfile.displayName || "Unnamed"}</a>
            </Card.Content>
        </Card>
    );
}

function InnerSideBarNavigation() {
    const { authenticated, currentProfile } = useGeneralContext();
    const navigate = useNavigate();
    return (
        <Segment vertical>
            {(authenticated && currentProfile) ? <ProfileContainer /> : null}
            <List
                animated
                size="huge"
                style={{ backgroundColor: 'white', padding: 10, borderRadius: 4, textAlign: 'left' }}
            >
                <List.Item className="cursor-pointer font-resize" onClick={() => navigate("/marketplace")}>
                    <Icon name="shopping cart" />
                    <List.Content>
                        <List.Header as={"h2"}>
                            Marketplace
                        </List.Header>
                    </List.Content>
                </List.Item>
                {(authenticated) ?
                    <List.Item style={{ marginTop: 4 }} className="cursor-pointer font-resize" onClick={() => navigate("/messages")}>
                        <Icon name="comment alternate" />
                        <List.Content>
                            <List.Header as={"h2"}>
                                Messages
                            </List.Header>
                        </List.Content>
                    </List.Item> : null}
                <List.Item style={{ marginTop: 4 }} className="cursor-pointer font-resize" onClick={() => { window.location.href = "https://business.chaguoo.com/register-business" }}>
                    <Icon name="building" />
                    <List.Content>
                        <List.Header as={"h2"}>
                            Register A Business
                        </List.Header>
                    </List.Content>
                </List.Item>
            </List>
            <Grid textAlign="left" className="text-color-black">
                <a href="/pricing">Pricing</a>
                <a href="/about">About</a>
                <a href="/support">Support</a>
                <a href="/policy">Policy</a>
                {(authenticated) ? <a href="https://business.chaguoo.com/">My Businesses</a> : null}
            </Grid>
        </Segment>
    );
}

export const SideBarNavigation = memo(InnerSideBarNavigation);