import { Container, Grid, Header, Segment } from "semantic-ui-react";
import { ResponsiveContainer } from "../components/DefaultResponsiveContainer";
import { Footer } from "../components/Footer";

export function PricingPage(){
    return(
        <ResponsiveContainer>
            <Segment style={{ padding: '0em' }} vertical>
                <Header as={'h1'} style={{margin: 20}}>Pricing</Header>
            </Segment>
            <Segment basic style={{ padding: '0em', height: '70vh'}} vertical>
                <Grid celled='internally' columns='equal' stackable>
                    <Grid.Row textAlign='center'>
                        <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                            <Container style={{alignItems: 'center', justifyContent: 'center'}}>
                                <Header as='h2' style={{ fontSize: '2em' }}>
                                    Chaguoo Account
                                </Header>
                                <Header as='h4' style={{ fontSize: '1.5em', marginBottom: '1em'}}>
                                    100% FREE
                                </Header>
                                <div>
                                    <p>Instant Messaging with Business Owners</p>
                                    <p>Product Search</p>
                                    <p>Authentication</p>
                                    <p>Feed to view your favourite business</p>
                                    <p>Like posts and Comment your thoughts</p>
                                </div>
                            </Container>
                        </Grid.Column>
                        <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                            <Header as='h3' style={{ fontSize: '2em' }}>
                                Chaguoo Business Account
                            </Header>
                            <Header as='h4' style={{ fontSize: '1.5em', marginBottom: '1em'}}>
                                12000 RWF per year
                            </Header>
                            <Container style={{alignItems: 'center', justifyContent: 'center'}}>
                                <div>
                                    <p>Free 1 month trial</p>
                                    <p>Publish communications about your business</p>
                                    <p>Publish Products</p>
                                    <p>Instant Messaging with your clients</p>
                                    <p>Reply to comments on your posts</p>
                                    <p>One account manager</p>
                                </div>
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
            <Footer/>
        </ResponsiveContainer>
    )
}