import { httpsCallable } from "@firebase/functions";
import { functions } from "./firebase";

export async function getProfileInfo() {
    const fun = httpsCallable(functions, "getProfileInfo");
    const resp: any = (await fun()).data;
    if (resp.status !== "success") return undefined;
    return resp.data;
}

export async function updateProfileInfo(newData: any) {
    const fun = httpsCallable(functions, "updateProfileInfo");
    const resp: any = (await fun({ data: newData })).data;
    if (resp.status !== "success") throw new Error(JSON.stringify(resp));
    return resp.data;
}

export async function requestAccountDeletion(reason: string) : Promise<string>{
    const fun = httpsCallable(functions, "requestAccountDeletion");
    const resp: any = (await fun({ reason: reason })).data;
    if (resp.status !== "success") throw new Error(JSON.stringify(resp));
    return resp.message;
}