import { Button, Icon, Segment } from "semantic-ui-react";
import { openInNewTab } from "../common/utils";
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";

export function SocialMediaLinks({ hide }: { hide?: boolean }) {
    return (
        <Segment basic>
            {hide ? <p style={{ textAlign: 'left' }}>You can find us on other platforms:</p> : null}
            <Button
                style={{ marginTop: 2 }}
                onClick={() => openInNewTab("https://www.linkedin.com/company/chaguoo")}
                color="blue">
                <Icon name='linkedin' />LinkedIn
            </Button>
            {/*<Button 
                style={{marginTop: 2}}
                onClick={() => openInNewTab("https://www.youtube.com/@chaguooTV")}
                color="red">
                <Icon name='youtube'/>Youtube
            </Button>*/}
        </Segment>
    )
}

export function ShareFacebook({ url }: { url: string }) {
    return (
        <FacebookShareButton url={url} style={{ marginRight: 5 }}>
            <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
    )
}

export function ShareWhatsapp({ url }: { url: string }) {
    return (
        <WhatsappShareButton url={url} style={{ marginRight: 5 }}>
            <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
    )
}

export function ShareTwitter({ url }: { url: string }) {
    return (
        <TwitterShareButton url={url} style={{ marginRight: 5 }}>
            <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
    )
}