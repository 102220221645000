import { createContext, useContext, useState } from "react";

interface HomeFeedContextProps {
    handledScrolled: any;
    setHandledScrolled: (any: any) => void;
}

const HomeFeedContext = createContext<HomeFeedContextProps>({
    handledScrolled: undefined, 
    setHandledScrolled: (any: any) => {}
});

export function HomeFeedContextProvider({children} : {children: React.ReactNode}){
    const [handledScrolled, setHandledScrolled] = useState<any>({});

    const value = {
        handledScrolled: handledScrolled, 
        setHandledScrolled: setHandledScrolled
    };

    return (
        <HomeFeedContext.Provider value={value}>
            {children}
        </HomeFeedContext.Provider>
    );
}

export const useHomeFeedContext = () => useContext(HomeFeedContext);