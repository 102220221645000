import { CarouselProvider, DotGroup, Slide, Slider } from "pure-react-carousel";
import { Icon, ImageProps } from "semantic-ui-react";
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useEffect, useRef, useState } from "react";
import { PostMediaContent } from "../models/models";
import ReactPlayer from 'react-player';


function gcd(a: number, b: number): number {
    return !b ? a : gcd(b, a % b);
}

function aspectRatio(width: number, height: number) {
    const divisor = gcd(width, height);
    return [width / divisor, height / divisor];
}

interface CustomVideoPlayerProps {
    src: string;
    wrapperId?: string;
}

function CustomVideoPlayer({ src, wrapperId }: CustomVideoPlayerProps) {
    const [width, setWidth] = useState<undefined | number>();
    const [height, setHeight] = useState<undefined | number>();

    useEffect(() => {
        const element = document.getElementById(wrapperId || "");
        if (element) {
            const width = element?.clientWidth || 0;
            const height = element?.clientHeight || 0;
            if (width > 0) {
                setWidth(width)
            }
            if (height > 0) {
                setHeight(height)
            }
        }
        const handleResize = () => {
            // Handle width change here
            const width = element?.clientWidth || 0;
            const height = element?.clientHeight || 0;
            if (width > 0) {
                setWidth(width)
            }
            if (height > 0) {
                setHeight(height)
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [wrapperId])

    return (
        <ReactPlayer
            url={src}
            playIcon={<Icon name="play" />}
            playing={false}
            controls={true}
            width={width}
            height={height}
        />
    );
}

interface CustomCardSlideProps extends ImageProps {
    index: number;
    src: string;
    type: 'video' | 'image' | string;
    wrapperId?: string;
    onImageLoad: (width: number, height: number) => void;
    cover?: boolean;
}


function OurImage({ src, cover, onImageLoad, activeOnClick }: any) {
    const imageRef = useRef<any>();

    const toggleFullscreen = () => {
        if (!document.fullscreenElement) {
            if (!imageRef.current) return;
            imageRef.current.requestFullscreen().catch((ignore: Error) => {
            });
        } else {
            document.exitFullscreen();
        }
    };

    return (
        <img
            alt="media"
            ref={imageRef}
            className={cover ? "our-image-cover" : "our-image"}
            src={src}
            onClick={activeOnClick ? toggleFullscreen : undefined}
            onLoad={(event: any) => {
                const [width, height] = aspectRatio(event.target.naturalWidth, event.target.naturalHeight);
                onImageLoad(width, height);
            }}
        />
    );
}

function CustomMediaSlide({ index, type, src, wrapperId, onImageLoad, cover }: CustomCardSlideProps) {
    const handler = {
        get: (target: any, name: string) => target.hasOwnProperty(name) ? target[name] : <p>Unknown Type {name}</p>
    }
    const componentsToLoad = new Proxy({
        "image": (
            <OurImage
                src={src}
                onImageLoad={onImageLoad}
                cover={cover}
            />
        ),
        "video": (
            <CustomVideoPlayer
                src={src}
                wrapperId={wrapperId}
            />
        )
    }, handler);

    return (
        <Slide index={index} key={index}>
            {componentsToLoad[type]}
        </Slide>
    );
}

interface MediaSliderProps {
    localFiles?: File[];
    hideDotGroup?: boolean;
    aspect?: boolean;
    cover?: boolean;
    remoteFiles?: PostMediaContent[];
}

export default function MediaSlider({ localFiles, remoteFiles, cover, hideDotGroup, aspect }: MediaSliderProps) {
    const [contents, setContents] = useState<PostMediaContent[]>([]);
    const [maxImageSize, setMaxImageSize] = useState({
        height: 1, width: 1
    });

    useEffect(() => {
        if (localFiles !== undefined) {
            setContents(localFiles.map((file) => ({ source: URL.createObjectURL(file), type: file.type.split("/")[0] })));
        } else if (remoteFiles !== undefined) {
            setContents(remoteFiles);
        }
    }, [localFiles, remoteFiles]);

    const onImageLoad = (width: number, height: number) => {
        if (height > maxImageSize.height) {
            setMaxImageSize((prev) => ({ ...prev, height }));
        }
        if (width > maxImageSize.width) {
            setMaxImageSize((prev) => ({ ...prev, width }));
        }
    }

    const heightToHave = aspect ? (maxImageSize.height || 1) : 1;
    const widthToHave = aspect ? (maxImageSize.width || 1) : 1;

    if (contents.length === 0) {
        return (
            <p>No media</p>
        );
    }

    return (
        <CarouselProvider
            naturalSlideWidth={widthToHave}
            naturalSlideHeight={heightToHave}
            totalSlides={contents.length}
        >
            <Slider
                id="postSlider"
            >
                {contents.map((content, index) => (
                    <CustomMediaSlide
                        key={index}
                        index={index}
                        src={content.source}
                        type={content.type}
                        wrapperId="postSlider"
                        onImageLoad={onImageLoad}
                        cover={cover}
                    />
                ))}
            </Slider>
            {(hideDotGroup || contents.length === 1) ? null :
                <div className="controls">
                    <DotGroup />
                </div>
            }
        </CarouselProvider>
    );
}