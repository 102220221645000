import { Container, Menu, Dropdown, Icon, Divider } from 'semantic-ui-react';
import { appName } from '../common/constant';
import { useGeneralContext } from '../storage/context/GeneralContext';
import { SearchComponent } from './SearchComponents';
import { useNavigate } from 'react-router-dom';
import { logout } from '../api/authentication';

interface DefaultHeaderProps{
    mobile?: boolean;
}

export function DefaultHeader({mobile} : DefaultHeaderProps) {
    const { authenticated, currentProfile } = useGeneralContext();
    const navigate = useNavigate();

    const stylesItem = {
        fontSize: 18,
    }

    if (mobile) {
        return (
            <Menu vertical style={{width: '100%', backgroundColor: 'transparent', textAlign: 'right'}}>
                <Menu.Item style={stylesItem} as='a' href='/' icon>
                    <Icon name='home' />
                    Home
                </Menu.Item>
                {authenticated ? 
                <Menu.Item style={stylesItem} as={'a'} href="/messages">
                    <Icon name='chat' />
                    Messages
                </Menu.Item> : null}
                <Menu.Item style={stylesItem} as='a' href='/marketplace' icon>
                    <Icon name='shopping cart' />
                    Marketplace
                </Menu.Item>
                <Menu.Item style={stylesItem} as='a' href='/pricing' icon>
                    <Icon name='euro sign' />
                    Pricing
                </Menu.Item>
                <Menu.Item style={stylesItem} as='a' href='/support' icon>
                    <Icon name='help' />
                    Support
                </Menu.Item>
                <Menu.Item style={stylesItem} as='a' href='/about' icon>
                    <Icon name='info' />
                    About
                </Menu.Item>
                <Menu.Item style={stylesItem} as='a' href="https://business.chaguoo.com/register-business" icon>
                    <Icon  name="building" />
                    Register A Business
                </Menu.Item>
                {authenticated ?
                <Menu.Item style={stylesItem} as='a' href="https://business.chaguoo.com/" icon>
                    <Icon  name="columns" />
                    My Business(es)
                </Menu.Item> : null}
                {authenticated ? 
                <Menu.Item style={stylesItem} as='a'>
                    {currentProfile?.displayName || "User"}
                </Menu.Item> : 
                <Menu.Item style={stylesItem} as='a' href='/login'>
                    Sign In
                    <Icon name='user' />
                </Menu.Item>}
                <Divider />
            </Menu>
        );
    }
    
    return (
        <Menu inverted style={{borderRadius: 0}} size='large' pointing secondary>
            <Container>
                <Menu.Item header onClick={() => navigate('/')}>
                    {appName}
                </Menu.Item>
                <SearchComponent />
                <Menu.Menu position='right'>
                    <Menu.Item 
                        name="Home" 
                        as='a'
                        href='/'
                        active={window.location.pathname === '/'}
                    />
                    {authenticated ? (
                        <Dropdown item text={currentProfile?.displayName || "User"}>
                            <Dropdown.Menu style={{backgroundColor: 'white'}}>
                                <Dropdown.Header content="Profile Management"/>
                                <Dropdown.Item as='a' href='/myprofile' text="Profile"/>
                                <Dropdown.Item as='a' href='/deleteAccount' text="Delete Account"/>
                                <Dropdown.Item text="Sign out" onClick={logout}/>
                                <Dropdown.Header content="Business Management"/>
                                <Dropdown.Item as='a' href="https://business.chaguoo.com/register-business" text="Register A Business"/>
                                <Dropdown.Item as='a' href="https://business.chaguoo.com/" text="My Business(es)"/>
                            </Dropdown.Menu>
                        </Dropdown>
                    )  : (
                        <Menu.Item 
                            name="Sign In"
                            as='a'
                            active={window.location.pathname.startsWith('/login') || window.location.pathname.startsWith('/signup')}
                            href='/login'
                        />
                    )}
                </Menu.Menu>
            </Container>
        </Menu>
    );
}