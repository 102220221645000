import { useState } from "react";
import { Button, Form, Grid, Icon, Placeholder, TextArea } from "semantic-ui-react";

export function MessageInput({onSendMessage, onError}: {onSendMessage: (txt: string) => Promise<void>, onError: (err: string) => void}){
    const [message, setMessage] = useState(''); 
    const [loading, setLoading] = useState(false);

    const sendClicked = async () => {
        setLoading(() => true);
        try {
            await onSendMessage(message);
            setMessage(() => '');
        } catch (err) {
            onError((err as Error).message);
        }
        finally {
            setLoading(() => false);
        }
    }

    return (
        <Grid style={{height: 'min-content'}}>
            <Grid.Row style={{justifyContent: 'space-around'}}>
                <Form style={{width: '80%'}}>
                    {loading ? 
                        <Placeholder style={{height: '1000px'}}>
                            <Placeholder.Line/>
                        </Placeholder>
                        :<TextArea 
                            style={{resize: 'none'}} 
                            rows={3} 
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />}
                </Form>
                <Button 
                    icon 
                    style={{width: '10%', minWidth: 'fit-content'}} 
                    onClick={() => sendClicked()}
                    disabled={loading || message.length === 0}
                    primary
                >
                    <Icon name="send"/>
                </Button>
            </Grid.Row>
        </Grid>
    )   
}