import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useGeneralContext } from '../storage/context/GeneralContext';
import { useEffect, useState } from 'react';
import { ResponsiveContainer } from './DefaultResponsiveContainer';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import { Footer } from './Footer';
import { SimpleErrorMessage } from './ErrorMessages';
import { getBusinessUrlBasename } from '../api/business';

interface ProtectedPageProps {
    page: JSX.Element
}

export function ProtectedPage({ page }: ProtectedPageProps) {
    const { authenticated } = useGeneralContext();

    if (!authenticated) {
        return <Navigate to={"/login?cause=authrequired"} state={{ from: window.location.pathname }} />
    }
    return page;
}

export function RedirectBusinessPageFromId() {
    const { business_id } = useParams();
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState("");
    const navigate = useNavigate();
    useEffect(() => {
        const getData = async () => {
            if (business_id) {
                setLoading(() => true);
                try {
                    const answer = await getBusinessUrlBasename(business_id);

                    if (answer && answer.url_basename) {
                        navigate(`/company/${answer.url_basename}`);
                    } else {
                        setLoading(() => false);
                    }
                } catch (err) {
                    setErr(() => (err as Error).message);
                } finally {
                    setLoading(() => false);
                }
            }
        }
        getData();
    }, [business_id, setLoading, setErr, navigate]);

    if (loading) {
        return (
            <ResponsiveContainer>
                <Segment basic style={{ backgroundColor: 'white', borderRadius: 10, height: '90vh' }}>
                    <Dimmer active>
                        <Loader>Loading</Loader>
                    </Dimmer>
                </Segment>
                <Footer />
            </ResponsiveContainer>
        )
    } else if (err) {
        return <SimpleErrorMessage error={err} />
    } else {
        return <SimpleErrorMessage error={"You shouldn't be on this page"} />
    }
}