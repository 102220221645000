import { useState, createContext, ReactNode, useEffect, useContext } from "react";
import { getBusinessProfileFromUrl } from "../../api/business";

interface CurrentBusinessPageContextProps {
    currentBusinessProfile: any;
    loading: boolean;
    setCurrentBusinessProfileURLBasename: (urlBasename: string) => void;
}

const CurrentBusinessPageContext = createContext<CurrentBusinessPageContextProps>({
    currentBusinessProfile: undefined, 
    loading: false,
    setCurrentBusinessProfileURLBasename: () => {}
});

export function CurrentBusinessPageContextProvider({children}: {children: ReactNode}){
    const [currentBusinessProfile, setCurrentBusinessProfile] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [currentBusinessProfileURLBasename, setCurrentBusinessProfileURLBasename] = useState("");

    useEffect(() => {
        const fetchProfile = async () => {
            setLoading((_) => true);
            getBusinessProfileFromUrl(currentBusinessProfileURLBasename)
            .then((response) => {
                setLoading((_) => false);
                if (!response){
                    return;
                }
                setCurrentBusinessProfile(response);
            })
        }
        if (currentBusinessProfileURLBasename){
            fetchProfile();
        }
    }, [currentBusinessProfileURLBasename]);
    const value = {
        currentBusinessProfile: currentBusinessProfile,
        loading: loading, 
        setCurrentBusinessProfileURLBasename: setCurrentBusinessProfileURLBasename
    }
    return (
        <CurrentBusinessPageContext.Provider value={value}>
            {children}
        </CurrentBusinessPageContext.Provider>
    )
}

export const useCurrentBusinessPageContext = () => useContext(CurrentBusinessPageContext);