import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { Dimmer, Divider, Grid, Loader } from "semantic-ui-react";
import MediaSlider from "../components/MediaSlider";
import { PostProfileHeader } from "../components/post/PostProfileHeader";
import { PostCommentSection } from "../components/post/PostCommentSection";
import { LikeButton } from "../components/post/LikeButton";
import { getFeedPostById } from "../api/feed";
import { ShareTwitter, ShareWhatsapp } from "../components/SocialMediaLinks";
import { Media } from "../storage/Media";
import { MetadataGenerator } from "../components/MetadataGenerator";

function MobileInnerPostModalScreen() {
    const modalRef = useRef<HTMLDivElement>(null);
    const scrollableRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [post, setPost] = useState<any>(location.state?.post);
    const { post_id } = useParams();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        /*const observerRefValue = modalRef.current;
        const scrollableRefValue = scrollableRef.current;
        if (observerRefValue) {
            disableBodyScroll(observerRefValue);
        }
        if (scrollableRefValue) {
            enableBodyScroll(scrollableRefValue);
        }
        return () => {
            if (observerRefValue) {
                enableBodyScroll(observerRefValue);
            }
            if (scrollableRefValue) {
                disableBodyScroll(scrollableRefValue);
            }
        };*/
    }, []);

    const getData = useCallback(async () => {
        if (!post_id) return;
        try {
            setLoading(() => true);
            const response = await getFeedPostById(post_id);
            setPost(() => response);
        } finally {
            setLoading(() => false);
        }
    }, [post_id]);

    useEffect(() => {
        const abortController = new AbortController();
        const getPostData = async () => {
            if (!location.state?.post && post_id) {
                getData();
            } else {
                setLoading(() => false);
            }
        }
        getPostData();
        return () => abortController.abort();
    }, [location.state?.post, post_id, getData]);

    const onGoingBack = useCallback(() => {
        if (location.state?.previousLocation === window.location.pathname) {
            navigate('/');
        } else {
            navigate(location.state?.previousLocation || '/');
        }
    }, [location, navigate]);

    if (loading || !post) {
        return (
            <div ref={modalRef} className="modal-wrapper" onClick={onGoingBack}>
                <div className="modal">
                    <Dimmer active>
                        <Loader>Loading</Loader>
                    </Dimmer>
                </div>
            </div>
        );
    }

    return (
        <div
            ref={modalRef}
            className="modal-wrapper"
            onClick={onGoingBack}
        >
            <MetadataGenerator
                title={post.store_data.name || "Post on Chaguoo"}
                description={post.content.text}
                image={post.content.media?.[0]?.url}
            />
            <div
                ref={scrollableRef}
                className="modal"
            >
                <Grid
                    stretched
                    onClick={(e: any) => e.stopPropagation()}
                >
                    <Grid.Row width={16}>
                        <PostProfileHeader
                            name={post.store_data.name}
                            image={post.store_data.image}
                            posted={post.posted}
                            store_id={post.store_id}
                            url_basename={post.store_data.url_basename}
                        />
                    </Grid.Row>
                    <Grid.Row width={16}>
                        {post.content.media
                            ? <MediaSlider remoteFiles={post.content.media} />
                            : null}
                    </Grid.Row>
                    <Grid.Row>
                        <p style={{
                            textAlign: 'left',
                            fontSize: '.9375rem',
                        }}>{post.content.text}</p>
                    </Grid.Row>
                    <Divider />
                    <Grid.Row className="comment-section">
                        <PostCommentSection
                            data={post}
                            refreshPost={getData}
                        />
                    </Grid.Row>
                    <Grid.Row
                        style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                    >
                        <LikeButton data={post} />
                        <span style={{ marginLeft: 10 }} />
                        <ShareWhatsapp url={window.location.href} />
                        <ShareTwitter url={window.location.href} />
                    </Grid.Row>
                </Grid>
            </div>
        </div>
    );
}

function DesktopInnerPostModalScreen() {
    const modalRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [post, setPost] = useState<any>(location.state?.post);
    const { post_id } = useParams();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const observerRefValue = modalRef.current;
        if (observerRefValue)
            disableBodyScroll(observerRefValue);
        return () => {
            if (observerRefValue) {
                enableBodyScroll(observerRefValue);
            }
        };
    }, []);

    const getData = useCallback(async () => {
        if (!post_id) return;
        try {
            setLoading(() => true);
            const response = await getFeedPostById(post_id);
            setPost(() => response);
        } finally {
            setLoading(() => false);
        }
    }, [post_id]);

    useEffect(() => {
        const abortController = new AbortController();
        const getPostData = async () => {
            if (!location.state?.post && post_id) {
                getData();
            } else {
                setLoading(() => false);
            }
        }
        getPostData();
        return () => abortController.abort();
    }, [location.state?.post, post_id, getData]);

    const onGoingBack = useCallback(() => {
        if (location.state?.previousLocation === window.location.pathname) {
            navigate('/');
        } else {
            navigate(location.state?.previousLocation || '/');
        }
    }, [location, navigate]);

    if (loading || !post) {
        return (
            <div ref={modalRef} className="modal-wrapper" onClick={onGoingBack}>
                <div className="modal">
                    <Dimmer active>
                        <Loader>Loading</Loader>
                    </Dimmer>
                </div>
            </div>
        );
    }

    return (
        <div ref={modalRef} className="modal-wrapper" onClick={onGoingBack}>
            <MetadataGenerator
                title={post.store_data.name || "Post on Chaguoo"}
                description={post.content.text}
                image={post.content.media?.[0]?.url}
            />
            <div className="modal">
                <Grid stretched onClick={(e: any) => e.stopPropagation()}>
                    <Grid.Column width={8}>
                        {post.content.media
                            ? <MediaSlider remoteFiles={post.content.media} />
                            : null}
                    </Grid.Column>
                    <Grid.Column width={8} stretched>
                        <Grid.Row style={{ height: '80px' }}>
                            <PostProfileHeader
                                name={post.store_data.name}
                                image={post.store_data.image}
                                posted={post.posted}
                                store_id={post.store_id}
                                url_basename={post.store_data.url_basename}
                            />
                        </Grid.Row>
                        <Grid.Row>
                            <p style={{
                                textAlign: 'left',
                                fontSize: '.9375rem',
                            }}>{post.content.text}</p>
                        </Grid.Row>
                        <Divider />
                        <Grid.Row className="comment-section" >
                            <PostCommentSection
                                data={post}
                                refreshPost={getData}
                            />
                        </Grid.Row>
                        <Grid.Row style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <LikeButton data={post} />
                            <span style={{ marginLeft: 10 }} />
                            <ShareWhatsapp url={window.location.href} />
                            <ShareTwitter url={window.location.href} />
                        </Grid.Row>
                    </Grid.Column>
                </Grid>
            </div>
        </div>
    );
}

export function PostModalScreen() {
    const location = useLocation();
    const navigate = useNavigate();
    const previousLocation = location.state?.previousLocation;

    useEffect(() => {
        if (!location.state?.previousLocation) {
            navigate(window.location.pathname,
                { state: { post: location.state?.post, previousLocation: "/" } }
            );
        }
    }, [location.state, navigate]);

    if (!previousLocation) {
        return null;
    } else {
        return (
            <>
                <Media at="mobile">
                    <MobileInnerPostModalScreen />
                </Media>
                <Media greaterThan="mobile">
                    <DesktopInnerPostModalScreen />
                </Media>
            </>
        )
    }
}