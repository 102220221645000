import { httpsCallable } from "firebase/functions";
import { functions, messaging } from "./firebase";
import { MessageContent, MessageContext } from "../models/models";
import { v4 as uuid_v4 } from "uuid";
import { getToken, onMessage } from "firebase/messaging";

const VALID_KEY = "BIWAopNuh5BRsEOAHny21EuqU4-3gFBLSuw4Z2Gse8a_hV0_d9tG98fwXQLEsjiRVuOTXkX6wKDlh9hAB0bSKU4";

export async function getMyConversations() {
    const fun = httpsCallable(functions, "getMyConversations");
    const resp: any = (await fun()).data;
    if (resp.status !== "success") return [];
    return resp.data;
}

export async function getConversationMessages(chat_id: string) {
    const fun = httpsCallable(functions, "getConversationMessages");
    const resp: any = (await fun({ chat_id: chat_id })).data;
    if (resp.status !== "success") return [];
    return resp.data.toReversed();
}

export async function sendAMessage(content: MessageContent) {
    const fun = httpsCallable(functions, "sendAMessage");
    const toSend: MessageContext = {
        ...content,
        message_id: uuid_v4()
    };
    const resp: any = (await fun({ message: toSend })).data;
    if (resp.status !== "success") throw new Error(JSON.stringify(resp));
    return resp.data;
}

export async function getChatMappingWithBusiness(store_id: string) {
    const fun = httpsCallable(functions, "getChatMappingWithBusiness");
    const resp: any = (await fun({ store_id: store_id })).data;
    if (resp.status !== "success") return undefined;
    return resp.data.chat_id;
}

export async function createChatMappingWihBusiness(store_id: string) {
    const fun = httpsCallable(functions, "createChatMappingWithBusiness");
    const resp: any = (await fun({ store_id: store_id })).data;
    if (resp.status !== "success") throw new Error(JSON.stringify(resp));
    return resp.data.chat_id;
}

export async function requestNotificationPermisssion(){
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
        await getMessagingDeviceToken();
    }
}

export async function getMessagingDeviceToken() {
    const msg = await messaging();
    if (!msg) return
    try {
        const fcmToken = await getToken(msg, { vapidKey: VALID_KEY });
        if (fcmToken) {
            return fcmToken;
        }
    } catch (error) {
    }
}

export async function notifyFCMToken() {
    const msg = await messaging();
    if (!msg) return;
    const token =  await getMessagingDeviceToken();
    if (!token) return;
    const fun = httpsCallable(functions, "registerFCMToken");
    const resp: any = (await fun({ token: token })).data;
    if (resp.status !== "success") {
        return;
    }
    onMessage(msg, (message) => {
        if (!message.notification) return
        new Notification(message.notification.title || "Notification", { body: message.notification.body });
    })
}

export async function onOpenUnreadConversation(chat_id: string){
    const fun = httpsCallable(functions, "onReadMessage");
    const resp: any = (await fun({ chat_id: chat_id })).data;
    if (resp.status !== "success") throw new Error(JSON.stringify(resp));
    return;
}