import { useNavigate } from "react-router-dom";
import { Grid, Image } from "semantic-ui-react";
import { getTimeStringFromFirebaseTimestamp } from "../../common/format";

export function PostProfileHeader({ image, name, posted, url_basename }: any) {
    const navigate = useNavigate();
    return (
        <Grid stretched>
            <Grid.Row stretched>
                <Grid.Column style={{ width: 'fit-content' }} stretched>
                    {image ? <Image className="our-image" src={image} alt={name} style={{ maxWidth: 50 }} rounded /> : null}
                </Grid.Column>
                <Grid.Column
                    textAlign="left"
                    style={{ width: 'auto', cursor: "pointer" }}
                    onClick={() => navigate(`/company/${url_basename}`)}
                >
                    <p style={{ fontSize: 18, fontWeight: 'bold' }}>{name}</p>
                    <p style={{ fontSize: 12, color: 'grey' }}>{getTimeStringFromFirebaseTimestamp(posted)}</p>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}