// ************************** Feed Related **************************

import { httpsCallable } from "@firebase/functions";
import { functions } from "./firebase";
import { ChatUser } from "../models/models";
import * as Sentry from "@sentry/react";
import { isMobile } from 'react-device-detect';

export async function queryPostsCollection(lastTimestamp?: any): Promise<[any[], any]> {
    const fun = httpsCallable(functions, "getFeedPosts");
    let resp: any;
    const before = performance.now();
    if (lastTimestamp) {
        resp = (await fun({ lastTimestamp: lastTimestamp })).data;
    } else {
        resp = (await fun()).data;
    }
    const after = performance.now();
    Sentry.metrics.distribution("feed_query_time", after - before, {
        tags: { platform: isMobile ? "mobile" : "web" },
        unit: "millisecond",
    });
    if (resp.status !== "success") return [[], undefined];
    if (resp.data.posts && resp.data.posts.length === 0) return [[], undefined];
    return [resp.data.posts, resp.data.posts[resp.data.posts.length - 1].posted];
}

export async function userLikeAPost(post_id: string) {
    const fun = httpsCallable(functions, "userLikeAPost");
    const resp: any = (await fun({ post_id: post_id })).data;
    if (resp.status !== "success") {
        Sentry.captureException(new Error(resp.message));
        throw new Error(resp.message);
    }
}

export async function userUnlikeAPost(post_id: string) {
    const fun = httpsCallable(functions, "userUnlikeAPost");
    const resp: any = (await fun({ post_id: post_id })).data;
    if (resp.status !== "success") {
        Sentry.captureException(new Error(resp.message));
        throw new Error(resp.message);
    }
}

export async function getUserProfileInfo(uid: string) {
    const fun = httpsCallable(functions, "getProfileInfoByUid");
    const resp: any = (await fun({ uid: uid })).data;
    if (resp.status !== "success") {
        Sentry.captureException(new Error(resp.message));
        throw new Error(resp.message);
    }
    return resp.data;
}

export async function commentOnPost(post_id: string, text: string, sender: ChatUser) {
    const fun = httpsCallable(functions, "commentOnPost");
    const data = {
        post_id: post_id,
        content: {
            text: text
        },
        sender: sender
    };
    const resp: any = (await fun(data)).data;
    if (resp.status !== "success") {
        Sentry.captureException(new Error(resp.message));
        throw new Error(resp.message);
    }
}

export async function deleteComment(post_id: string, comment_id: string, sender: ChatUser) {
    const fun = httpsCallable(functions, "deleteComment");
    const data = {
        post_id: post_id,
        comment_id: comment_id,
        sender: sender
    };
    const resp: any = (await fun(data)).data;
    if (resp.status !== "success") {
        Sentry.captureException(new Error(resp.message));
        throw new Error(resp.message);
    }
}

export async function getFeedPostById(post_id: string) {
    const fun = httpsCallable(functions, "getFeedPostById");
    const resp: any = (await fun({ post_id: post_id })).data;
    if (resp.status !== "success") {
        Sentry.captureException(new Error(resp.message));
        throw new Error(resp.message);
    }
    return resp.data;
}