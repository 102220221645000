export const appName = "Chaguoo";

export const missingImage = "https://react.semantic-ui.com/images/wireframe/image.png";
export const missingProfileImage = "https://react.semantic-ui.com/images/avatar/large/daniel.jpg";

export const MAX_MEDIA_SIZE = 1024 * 1024 * 32; // 32MB

export const CurrenciesOptions = [
    { key: "EUR", value: "EUR", text: "Euro"}, 
    { key: "RWF", value: "RWF", text: "Rwandan Franc"}, 
    { key: "USD", value: "USD", text: "US Dollar"}, 
];