import { PhoneNumberUtil } from 'google-libphonenumber';
import { RecaptchaVerifier } from "firebase/auth";
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Container, Form, Grid, Input, Message, Segment } from "semantic-ui-react";
import { PhoneInput } from "react-international-phone";
import { loginWithPhoneNumber } from '../api/authentication';
import { auth } from '../api/firebase';
import * as Sentry from '@sentry/react';

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
    try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
        return false;
    }
};

export function PhoneFormLogin({ onceLoginFinished }: { onceLoginFinished: () => void }) {
    const mounted = useRef(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmationResult, setConfirmationResult] = useState<any>(null);
    const isValid = isPhoneValid(phoneNumber);
    const [verifier, setVerifier] = useState<RecaptchaVerifier | null>(null);

    const handleRequestForVerificationCode = useCallback(async () => {
        try {
            setError(() => "");
            setLoading(() => true);
            if (!mounted.current) return;
            if (!verifier) return;
            const confirmationResult = await loginWithPhoneNumber(phoneNumber, verifier);
            if (!mounted.current) return;
            setConfirmationResult(confirmationResult);
        } catch (error) {
            if (!mounted.current) return;
            setError((error as Error).message + ' Please try again later.');
            Sentry.captureException(error);
            if (verifier) {
                verifier.clear();
                setVerifier(() => null);
            }
        } finally {
            if (!mounted.current) return;
            setLoading(() => false);
        }
    }, [phoneNumber, verifier]);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        const recaptchaContainer = document.getElementById('recaptcha-container');
        if (!recaptchaContainer) return;
        const verifier = new RecaptchaVerifier(auth, "get-verification-code-button", {
            size: 'invisible',
            callback: () => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                // handleRequestForVerificationCode();
            },
            'expired-callback': () => {
                console.log('expired-callback');
            },
        });
        setVerifier(verifier);
        return () => {
            if (verifier) {
                verifier.clear();
            }
        }
    }, []);

    const onChangePhoneNumber = useCallback(async (phone: string) => {
        setPhoneNumber(phone);
        if (confirmationResult) {
            setConfirmationResult(null);
        }
    }, [setPhoneNumber, confirmationResult]);

    const handleVerificationCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVerificationCode(e.target.value);
    };

    const handleVerificationSubmit = useCallback(async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        try {
            if (!confirmationResult) return;
            if (!mounted.current) return;
            setLoading(() => true);
            // Confirm the verification code
            await confirmationResult.confirm(verificationCode);
            if (!mounted.current) return;
            onceLoginFinished();
        } catch (error) {
            if (!mounted.current) return;
            setError((error as Error).message + ' Please try again later.');
            Sentry.captureException(error);
        } finally {
            if (!mounted.current) return;
            setLoading(() => false);
        }
    }, [confirmationResult, verificationCode, onceLoginFinished]);

    return (
        <Segment basic>
            <Grid centered verticalAlign='middle'>
                <Grid.Column width={12}>
                    <Form>
                        <Form.Field>
                            <label>Phone Number</label>
                            <PhoneInput
                                placeholder='Phone Number'
                                onChange={(phone) => onChangePhoneNumber(phone)}
                            />
                            {!isValid && <Message negative>Invalid phone number</Message>}
                        </Form.Field>
                        <Button
                            id="get-verification-code-button"
                            primary
                            disabled={!isValid}
                            onClick={handleRequestForVerificationCode}
                            style={{ marginTop: '1em' }}
                            loading={loading}
                            active={!loading}
                        >
                            Get Verification Code
                        </Button>

                        {confirmationResult && (
                            <>
                                <Form.Field>
                                    <label>Verification Code</label>
                                    <Input
                                        placeholder='Verification Code'
                                        value={verificationCode}
                                        onChange={handleVerificationCodeChange}
                                    />
                                </Form.Field>
                                <Button
                                    id="verify-button"
                                    type='submit'
                                    onClick={handleVerificationSubmit}
                                    positive
                                    style={{ marginTop: '1em' }}
                                    loading={loading}
                                    active={!loading}
                                >
                                    Verify
                                </Button>
                            </>
                        )}
                        {!confirmationResult ?
                            <Container>
                                <div id='recaptcha-container'></div>
                            </Container> : null}
                        {error && <Message negative>{error}</Message>}
                    </Form>
                </Grid.Column>
            </Grid>
        </Segment>
    );
}