import { useState } from "react";
import { Button, Form, Grid, Header, Message, Segment, TextArea } from "semantic-ui-react";
import { ResponsiveContainer } from "../components/DefaultResponsiveContainer";
import { Helmet } from "react-helmet-async";
import { submitSupportRequest } from "../api/authentication";
import { Footer } from "../components/Footer";

const categories = [
    {key: "Technical / Bug", text: "Technical / Bug", value: "Technical / Bug"},
    {key: "Legal", text: "Legal", value: "Legal"},
    {key: "Request Feature", text: "Request Feature", value: "Request Feature"},
    {key: "Customer Service", text: "Customer Service", value: "Customer Service"},
    {key: "Other", text: "Other", value: "Other"},
]

type FieldError = {content: string, pointing: string} | undefined

export default function SupportPage() {
    const [email, setEmail]       = useState('');
    const [message, setMessage]   = useState('');
    const [category, setCategory] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError]     = useState("");
    const [invalid, setInvalid] = useState<{email: FieldError, category: FieldError, message: FieldError, phone: FieldError}>({email: undefined, category: undefined, message: undefined, phone: undefined});

    const validField = () => {
        const toSet: any = {};
        if (email.length === 0 && phoneNumber.length === 0){
            toSet.email = {content: "Please enter an email", pointing: "below"};
            toSet.phone = {content: "Please enter a phone number", pointing: "below"}; 
        }
        if (message.length === 0 && phoneNumber.length === 0){
            toSet.email = {content: "Please enter an email", pointing: "below"};
        } else if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email) && phoneNumber.length === 0){
            toSet.email = {content: "Please enter a valid email address.", pointing: "below"};
        }
        if (!categories.some((val) => val.text === category)){
            toSet.category = {content: "Select a valid category", pointing: "below"};
        }
        if (message.trim().length < 20){
            toSet.message = {content: "The message has to be atleast 20 characters"}
        }
        if (phoneNumber.length > 0 && !/^\+?[0-9]{10,14}$/.test(phoneNumber)){
            toSet.phone = {content: "Please enter a valid phone number", pointing: "below"};
        }
        setInvalid((prev) => toSet);
        return Object.keys(toSet).length === 0;
    }

    const handleSubmit = () => {
        setError((prev) => "");
        setSuccess((prev) => "");
        if (!validField()){
            return
        }
        setLoading((prev) => true);
        submitSupportRequest({
            email: email,
            phoneNumber: phoneNumber,
            message: message,
            category: category
        }).then((res) => {
            setLoading((prev) => false);
            setSuccess((prev) => res);   
            setCategory(() => "");
            setMessage(() => "");
            setEmail(() => "");
        }).catch((err) => {
            setLoading((prev) => false);
            setError((err as Error).message)
        });
    }

    return (
        <ResponsiveContainer>
            <Helmet> 
               <title>Chaguoo - Support</title>
               <meta name="description" content="Chaguoo support page. You can contact us here." />
               <meta name="keywords" content="Chaguoo, African Businesses, Shopping, African E-commerce, businesses Directory, Rwandan Businesses, Contact" />
               <meta property="og:type" content="website" />
            </Helmet>
            <Header as='h3' style={{ fontSize: '2em'}}>
                Support / Contact
            </Header>
            <Segment basic>
                <p>Please refer to our <a href="/faq">FAQ</a>, to see if your questions are not answered there.</p>
            </Segment>
            <Segment basic>
                <Grid centered verticalAlign='middle'>
                    <Grid.Column width={12}>
                        <Form 
                            onSubmit={handleSubmit} 
                            loading={loading} 
                        >
                            {success.length > 0 && <Message success visible={success.length > 0}>
                                {success}
                            </Message>}
                            {error.length > 0 && <Message negative visible={error.length > 0}>
                                {error}
                            </Message>}
                            <Form.Input
                                fluid
                                label='Email'
                                placeholder='Please specify email to be contacted at'
                                error={invalid.email}
                                onChange={(ev, {value}) => { setEmail(value); setInvalid((prev) => ({...prev, email: undefined})) }}
                            />
                            <Form.Input
                                fluid
                                label='Phone'
                                placeholder='Please specify phone to be contacted at'
                                error={invalid.phone}
                                onChange={(ev, {value}) => { setPhoneNumber(value); setInvalid((prev) => ({...prev, phone: undefined})) }}
                            />
                            <Form.Select
                                fluid
                                label='Category'
                                options={categories}
                                placeholder='Category'
                                error={invalid.category}
                                onChange={(ev, {value}) => { setCategory(value as string); setInvalid((prev) => ({...prev, category: undefined})) }}
                            />
                            <Form.Field
                            >
                                <label>Message</label>
                                <TextArea 
                                    placeholder='We are all ears...' 
                                    value={message} 
                                    minLength={10}
                                    onChange={(ev, {value}) => { setMessage(value as string); setInvalid((prev) => ({...prev, message: undefined})) }}
                                />
                                {invalid.message && <Message negative>{invalid.message.content}</Message>}
                            </Form.Field>
                            <Button 
                                type='submit' 
                                positive
                                disabled={loading}
                            >
                                Submit
                            </Button>
                        </Form>
                    </Grid.Column>
                </Grid>
            </Segment>
            <Segment basic>
                <p>Or you can reach us at </p>
                <a href="mailto:contact@chaguoo.com">contact@chaguoo.com</a>
                <p>+250 798 696 773</p>
        </Segment>
        <Footer />
        </ResponsiveContainer>
    );
}