import { memo } from "react";
import { Grid, Segment, SemanticTEXTALIGNMENTS } from "semantic-ui-react";
import { GoogleStoreComponent } from "./GoogleStoreComponent";
import { AppleStoreComponent } from "./AppleStoreComponent";
import { SocialMediaLinks } from "./SocialMediaLinks";

function InnerAdsBarNavigation({textAlign}: {textAlign?: SemanticTEXTALIGNMENTS | undefined}){
    return (
        <Segment vertical basic className="horizontal-center-segment" style={{marginTop: 10, backgroundColor: 'transparent'}} textAlign={textAlign}>
            <Grid style={{width: '100%', height: 'fit-content'}}>
                <Grid.Row style={{backgroundColor: 'white', borderRadius: 4, width: '100%'}}>
                    <p style={{paddingLeft: 20}}>For better user experience download our mobile app.</p>
                </Grid.Row>
                <Grid.Row className="font-resize" style={{backgroundColor: 'white', borderRadius: 4, width: '100%'}}>
                    <AppleStoreComponent theme="dark"/>
                    <GoogleStoreComponent theme="light"/>
                </Grid.Row>
                <Grid.Row className="horizontal-center-segment" style={{backgroundColor: 'white', borderRadius: 4, marginTop: 10}}>
                    <SocialMediaLinks />
                </Grid.Row>
            </Grid>
        </Segment>
    );
}

export const AdsBarNavigation = memo(InnerAdsBarNavigation);