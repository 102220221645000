import { memo } from "react";
import { AppStoreButton } from "react-mobile-app-button";

function InnerAppleStoreComponent({theme}: {theme: 'light' | 'dark'}) {
    return (
        <AppStoreButton
            height={50}
            url={"https://apps.apple.com/us/app/chaguoo/id6468029786?platform=iphone"}
            theme={theme}
            className={"apple-store tp-m-1"}
        />
    );
}

export const AppleStoreComponent = memo(InnerAppleStoreComponent);