import { Button, Modal} from 'semantic-ui-react';

interface ConfirmationPopupProps {
    open: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    header: string;
    text: string;
    isNegative?: boolean;
}

export function ConfirmationPopup(props: ConfirmationPopupProps){

    return (
        <Modal
            className='confirmation-popup'
            size={"mini"}
            open={props.open}
        >
            <Modal.Header>{props.header}</Modal.Header>
            <Modal.Content>
            <p>{props.text}</p>
            </Modal.Content>
            <Modal.Actions>
            <Button onClick={props.onCancel}>
                Cancel
            </Button>
            <Button negative={props.isNegative} onClick={props.onConfirm}>
                Confirm
            </Button>
            </Modal.Actions>
        </Modal>
    )
}