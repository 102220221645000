import { Grid, Header, Segment } from "semantic-ui-react";
import { ResponsiveContainer } from "../components/DefaultResponsiveContainer";
import { Helmet } from "react-helmet-async";
import { Footer } from "../components/Footer";

export default function PolicycyPage(){
    return (
        <ResponsiveContainer>
            <Helmet> 
               <title>Chaguoo - Policy</title>
               <meta name="description" content="The terms and conditions of using the Chaguoo services." />
               <meta name="keywords" content="Chaguoo, African Businesses, Shopping, African E-commerce, businesses Directory, Rwandan Businesses, Policy Privacy" />
               <meta property="og:type" content="website" />
            </Helmet>
            <Header as='h3' style={{ fontSize: '2em'}}>
                Terms and Conditions
            </Header>
            <Segment style={{ padding: '2em 0em', textAlign:"start"}} vertical>
                <Grid container stackable verticalAlign='middle'>
                    <Grid.Row>
                        <Grid.Column style={{justifyContent: 'left', display: 'flex'}}>
                            <p style={{ textAlign:"start"}}>
                                These Terms of Use (or “Terms”) govern your use of Chaguoo, except where we expressly
                                state that separate terms (and not these) apply, and provide information about the Chaguoo
                                Service (the “Service”), outlined below. When you create a Chaguoo account or use Chaguo,
                                you agree to these terms.
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column style={{justifyContent: 'left', display: 'flex'}}>
                            <h4>The Chaguoo Service</h4>
                            <p>
                                We agree to provide you with the Chaguoo Service. The Service includes all of the Chaguoo
                                products, features, applications, services, technologies, and software that we provide to
                                advance Chaguoo's mission: To bring you closer to the people, products and services that you
                                search. The Service is made up of the following aspects:
                            </p>
                            <ul>
                                <li style={{margin: '1em'}}>
                                Offering personalised opportunities to create, connect, communicate,
                                discover and share. People are different. So we offer you different types of
                                accounts and features to help you create, share, grow your presence, and
                                communicate with people on and off Chaguoo. We also want to strengthen your
                                relationships through shared experiences that you actually care about. So we
                                build systems that try to understand who and what you and others care about,
                                and use that information to help you create, find, join and share in experiences
                                that matter to you. Part of that is highlighting content, features, offers and
                                accounts that you might be interested in, and offering ways for you to
                                experience Chaguoo, based on things that you and others do on and off Chaguoo.
                                </li>
                                <li style={{margin: '1em'}}>
                                Promoting safety, integrity and security on and across the Chaguoo
                                Products.
                                We develop and use tools and offer resources to our community members that
                                help to make their experiences positive and inclusive, including when we think
                                they might need help. We also have teams and systems that work to combat
                                abuse and violations of our Terms and policies, as well as harmful and deceptive
                                behavior. We use all the information we have-including your information-to try
                                to keep our platform secure. We also may share information about misuse or
                                harmful content with law enforcement. 
                                </li>
                                <li style={{margin: '1em'}}>
                                Developing and using technologies that help us consistently serve our
                                growing community.
                                Organizing and analyzing information for our growing community is central to
                                our Service. A big part of our Service is creating and using cutting-edge
                                technologies that help us personalize, protect, and improve our Service on an
                                incredibly large scale for a broad global community. Technologies like artificial
                                intelligence and machine learning give us the power to apply complex processes
                                across our Service. Automated technologies also help us ensure the functionality
                                and integrity of our Service.
                                </li>
                                <li style={{margin: '1em'}}>
                                Communicating with you.
                                We may need to send you communications, like emails or in-product notices, to
                                respond to you or inform you about any product-related issues, research, or our
                                terms and policies. 
                                </li>
                                <li style={{margin: '1em'}}>
                                Ensuring access to our Service.
                                To operate our global Service, we must store and transfer data across our
                                systems around the world, including outside of your country of residence. The
                                use of this global infrastructure is necessary and essential to provide our
                                Service. This infrastructure may be owned or operated by Chaguoo.
                                </li>
                                <li style={{margin: '1em'}}>
                                Connecting you with brands, products, and services in ways you care
                                about.
                                Subject to the choices and settings you select, we use information from Chaguoo
                                to personalise your experience on Chaguoo, including by showing you ads, offers
                                and other sponsored content that we believe will be meaningful to you. And we
                                try to make that content as relevant as all of your other experiences on Chaguoo.
                                The ads we show you on the Service will always be based on basic information:
                                your age, the gender you provide, and location. They'll also be based on certain
                                device information, like the device you're using and the language you choose on
                                that device, as well as information about the ads we show you and how you
                                engage with those ads. We require ads to be based on this information to ensure
                                that ads are appropriate to you and the device you're seeing them on, and to
                                comply with relevant advertising and other applicable laws, guidance and
                                standards. This helps to avoid advertising a flower shop in Bujumbura to
                                someone in Kigali or showing you the same ad a number of times. We also need
                                to use this information to support the infrastructure and other costs of providing
                                our Service free of charge to you. Consistent with your choices, we also use
                                other information to personalise the ads you are shown. 
                                </li>
                                <li style={{margin: '1em'}}>
                                Providing and improving Chaguoo Products.
                                We use the information we have to develop, test, and improve our Service and
                                collaborate with others on research to make our Service better and contribute to
                                the well-being of our community. This includes analyzing the information we
                                have about our users and understanding how people use our Services, for
                                example by conducting surveys and testing and troubleshooting new features
                                </li>
                            </ul>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column style={{justifyContent: 'left', display: 'flex'}}>
                            <h4>How our service is funded</h4>
                            <p style={{textAlign:"start"}}>
                            Our services are funded from monthly subscriptions of stores registered on Chaguoo Web,
                            Owners Equity and possible grants and sponsorships that may arise in the near future.
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column style={{justifyContent: 'left', display: 'flex'}}>
                            <h4>The Privacy Policy</h4>
                            <p>
                            Chaguoo Web is committed to user privacy.
                            Certain information about the user, such as Internet Protocol (IP) addresses, navigation
                            through site, software used, time spent and other similar information, may be stored on third
                            party servers in small data pieces known as "cookies", which may also be written to the
                            User's computer system.
                            These cookies will not specifically identify the User.
                            The information will be used internally only for web portal traffic analysis.
                            If the user specifically provides unique identifying information, such as name, address and
                            other information on forms stored on this portal, such information will only be used for
                            statistical purposes and will not be published for general access.
                            Chaguoo Web, however, assumes no responsibility for the security of this information.
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <h4>Your Commitments</h4>
                            <p>In return for our commitment to provide the Service, we require you to make the below  commitments to us</p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <h4>Who Can Use Chaguoo.</h4>
                            <p>We want our Service to be as open and inclusive as possible, but we
                            also want it to be safe, secure, and in accordance with the law. So, we need you to commit to
                            a few restrictions in order to be part of the Chaguoo community.</p>
                            <ul>
                                <li>
                                You must be at least 18 years old for store owners and 16 years old for client
accounts.
                                </li>
                                <li>
                                You must not be prohibited from receiving any aspect of our Service under
applicable laws or engaging in payments related Services if you are on an
applicable denied party listing.
                                </li>
                                <li>
                                We must not have previously disabled your account for violation of law or any
of our policies.
                                </li>
                            </ul>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <h4>How You Can't Use Chaguoo Web.</h4>
                            <p>Providing a safe and open Service for a broad
                            community requires that we all do our part.</p>
                            <ul>
                                <li>
                                You can't impersonate others or provide inaccurate information.
                                You don't have to disclose your identity on Chaguoo, but you must provide us
                                with accurate and up to date information (including registration information),
                                which may include providing personal data. Also, you may not impersonate
                                someone or something you aren't, and you can't create an account for someone
                                else unless you have their express permission.
                                </li>
                                <li>
                                You can't do anything unlawful, misleading, or fraudulent or for an illegal
or unauthorized purpose.
                                </li>
                                <li>
                                You can't violate (or help or encourage others to violate) these Terms or
our policies. 
                                </li>
                                <li>
                                You can't do anything to interfere with or impair the intended operation of
the Service.
This includes misusing any reporting, dispute, or appeals channel, such as by
making fraudulent or groundless reports or appeals.
                                </li>
                                <li>
                                You can't attempt to create accounts or access or collect information in
unauthorized ways. This includes creating accounts or collecting information in an automated way
without our express permission.
                                </li>
                                <li>
                                You can't sell, license, or purchase any account or data obtained from us or
                                our Service.
                                This includes attempts to buy, sell, or transfer any aspect of your account
                                (including your username); solicit, collect, or use login credentials or badges of
                                other users; or request or collect Chaguoo usernames, passwords, or
                                misappropriate access tokens.
                                </li>
                                <li>
                                You can't post someone else's private or confidential information without
                                permission or do anything that violates someone else's rights, including
                                intellectual property rights (e.g., copyright infringement, trademark
                                infringement, counterfeit, or pirated goods).
                                You may use someone else's works under exceptions or limitations to copyright
                                and related rights under applicable law. You represent you own or have obtained
                                all necessary rights to the content you post or share. 
                                </li>
                                <li>
                                You can't modify, translate, create derivative works of, or reverse engineer
our products or their components.
                                </li>
                                <li>
                                You can't use a domain name or URL in your username without our prior
written consent.
                                </li>
                            </ul>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <h4>Permissions You Give to Us.</h4>
                            <p>
                            As part of our agreement, you also give us permissions that we
need to provide the Service
                            </p>
                            <ul>
                                <li>
                                We do not claim ownership of your content, but you grant us a license to
                                use it.
                                Nothing is changing about your rights in your content. We do not claim
                                ownership of your content that you post on or through the Service and you are
                                free to share your content with anyone else, wherever you want. However, we
                                need certain legal permissions from you (known as a “license”) to provide the
                                Service. When you share, post, or upload content that is covered by intellectual
                                property rights (like photos or videos) on or in connection with our Service, you
                                hereby grant to us a non-exclusive, royalty-free, transferable, sub-licensable,
                                worldwide license to host, use, distribute, modify, run, copy, publicly perform
                                or display, translate, and create derivative works of your content (consistent
                                with your privacy and application settings). This license will end when your
                                content is deleted from our systems. You can delete content individually or all at
                                once by deleting your accoun
                                </li>
                                <li>
                                Permission to use your username, profile picture, and information about
                                your relationships and actions with accounts, ads, and sponsored content.
                                You give us permission to show your username, profile picture, and information
                                about your actions (such as likes) or relationships (such as follows) next to or in
                                connection with accounts, ads, offers, and other sponsored content that you
                                follow or engage with that are displayed on Chaguoo Products, without any
                                compensation to you. For example, we may show that you liked a sponsored
                                post created by a brand that has paid us to display its ads on Chaguoo. As with
                                actions on other content and follows of other accounts, actions on sponsored
                                content and follows of sponsored accounts can be seen only by people who have
                                permission to see that content or follow. We will also respect your ad settings.
                                </li>
                                <li>
                                You agree that we can download and install updates to the Service on your
device.
                                </li>
                            </ul>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <h4>Additional Rights We Retain</h4>
                            <ul>
                                <li>
                                If you select a username or similar identifier for your account, we may change it
if we believe it is appropriate or necessary (for example, if it infringes
someone's intellectual property or impersonates another user).
                                </li>
                                <li>
                                If you use content covered by intellectual property rights that we have and make
available in our Service (for example, images, designs, videos, or sounds we
provide that you add to content you create or share), we retain all rights to our
content (but not yours).
                                </li>
                                <li>
                                You can only use our intellectual property and trademarks or similar marks as
expressly permitted with our prior written permission.
                                </li>
                                <li>
                                You must obtain written permission from us or under an open source license to
modify, create derivative works of, decompile, or otherwise attempt to extract
source code from us.
                                </li>
                            </ul>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <h4>Content Removal and Disabling or Terminating Your Account</h4>
                            <ul>
                                <li>
                                We can remove any content or information you share on the Service if we
                                believe that it violates these Terms of Use, our policies (including our Chaguoo
                                Community Guidelines), or we are required to do so by law. We can refuse to
                                provide or stop providing all or part of the Service to you (including terminating
                                or disabling your access to the Chaguoo Web products) immediately to protect
                                our community or services, or if you create risk or legal exposure for us, violate
                                these Terms of Use or our policies (including our Chaguoo Community
                                Guidelines), if you repeatedly infringe other people's intellectual property
                                rights, or where we are required to do so by law. We can also terminate or
                                change the Service, remove or block content or information shared on our
                                Service, or stop providing all or part of the Service if we determine that doing so
                                is reasonably necessary to avoid or mitigate adverse legal or regulatory impacts
                                on us. In some cases when we remove content, we will let you know and explain
                                any options you have to request another review, unless you seriously or
                                repeatedly violate these Terms or if doing so may expose us or others to legal
                                liability; harm our community of users; compromise or interfere with the
                                integrity or operation of any of our services, systems, or products; where we are
                                restricted due to technical limitations; or where we are prohibited from doing so
                                for legal reasons. If you believe your account has been terminated in error, or
                                you want to disable or permanently delete your account, consult our Help
                                Center. When you request to delete content or your account, the deletion process
                                will automatically begin no more than 30 days after your request. It may take up 
                                to 90 days to delete content after the deletion process begins. While the deletion
                                process for such content is being undertaken, the content is no longer visible to
                                other users, but remains subject to these Terms of Use and our Privacy Policy.
                                After the content is deleted, it may take us up to another 90 days to remove it
                                from backups and disaster recovery systems
                                </li>
                                <li>
                                Content will not be deleted within 90 days of the account deletion or content
                                deletion process beginning in the following situations:
                                    <ul>
                                        <li>
                                            where your content has been used by others in accordance with
                                            this license and they have not deleted it (in which case this license
                                            will continue to apply until that content is deleted); or
                                        </li>
                                        <li>
                                            where deletion within 90 days is not possible due to technical
                                            limitations of our systems, in which case, we will complete the
                                            deletion as soon as technically feasible; or
                                        </li>
                                        <li>
                                            where deletion would restrict our ability to:
                                            <ul>
                                                <li>
                                                    investigate or identify illegal activity or violations of
                                                    our terms and policies (for example, to identify or
                                                    investigate misuse of our products or systems);
                                                </li>
                                                <li>
                                                    protect the safety and security of our products, systems, and users;
                                                </li>
                                                <li>
                                                    comply with a legal obligation, such as the
                                                    preservation of evidence; or
                                                </li>
                                                <li>
                                                    comply with a request of a judicial or administrative
                                                    authority, law enforcement or a government agency;
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            in which case, the content will be retained for no longer than is
                                            necessary for the purposes for which it has been retained (the
                                            exact duration will vary on a case-by-case basis).
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    If you delete or we disable your account, these Terms shall terminate as an
                                    agreement between you and us, but this section and the section below called
                                    "Our Agreement and What Happens if We Disagree" will still apply even after
                                    your account is terminated, disabled, or deleted.
                                </li>
                            </ul>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <h4>Our Agreement and What Happens if We Disagree</h4>
                            <h4>Our Agreement</h4>
                            <ul>
                                <li>
                                    Your use of music on the Service is also subject to our Guidelines, If you use
                                    certain other features or related services, you will be provided with an
                                    opportunity to agree to additional terms that will also become a part of our
                                    agreement. 
                                </li>
                                <li>
                                    If any of those terms conflict with this agreement, those other terms will govern.
                                </li>
                                <li>
                                    If any aspect of this agreement is unenforceable, the rest will remain in effect.
                                </li>
                                <li>
                                    Any amendment or waiver to our agreement must be in writing and signed by
                                    us. If we fail to enforce any aspect of this agreement, it will not be a waiver.
                                </li>
                                <li>
                                    We reserve all rights not expressly granted to you.
                                </li>
                            </ul>
                            <h4>Who Has Rights Under this Agreement.</h4>
                            <ul>
                                <li>This agreement does not give rights to any third parties.</li>
                                <li>You cannot transfer your rights or obligations under this agreement without our consent</li>
                                <li>
                                    Our rights and obligations can be assigned to others. For example, this could
                                    occur if our ownership changes (as in a merger, acquisition, or sale of assets) or
                                    by law.
                                </li>
                            </ul>
                            <h4>Who Is Responsible if Something Happens.</h4>
                            <ul>
                                <li>    
                                    We will use reasonable skill and care in providing our Service to you and in
                                    keeping a safe, secure, and error-free environment, but we cannot guarantee that
                                    our Service will always function without disruptions, delays, or imperfections.
                                    Provided we have acted with reasonable skill and care, we do not accept
                                    responsibility for: losses not caused by our breach of these Terms or otherwise
                                    by our acts; losses which are not reasonably foreseeable by you and us at the
                                    time of entering into these Terms; any offensive, inappropriate, obscene,
                                    unlawful, or otherwise objectionable content posted by others that you may
                                    encounter on our Service; and events beyond our reasonable control.
                                </li>
                                <li>
                                    The above does not exclude or limit our liability for death, personal injury, or
                                    fraudulent misrepresentation caused by our negligence. It also does not exclude
                                    or limit our liability for any other things where the law does not permit us to do
                                    so.
                                </li>
                            </ul>
                            <h4>How We Will Handle Disputes.</h4>
                            <ul>
                                <li>
                                    If a claim or dispute arises out of or relates to your use of the Service as a consumer, both you
                                    and us agree that you may resolve your individual claim or dispute against us, and we may
                                    resolve our claim or dispute against you, in any competent court in the country of your main
                                    residence that has jurisdiction over your claim or dispute, and the laws of that country will
                                    apply without regard to conflict of law provisions.
                                </li>
                                <li>
                                    If a claim or dispute arises between us that relates to use of the Service in any other capacity,
                                    including, but not limited to, access or use of the Service for a business or commercial
                                    purpose, you agree that any such claim or dispute must be resolved in a competent court in
                                    Rwanda and Burundi. East African Community law will apply without regard to conflict of
                                    law provisions.
                                </li>
                            </ul>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <h4>Unsolicited Material.</h4>
                            <p>
                                We always appreciate feedback or other suggestions, but may use them without any
                                restrictions or obligation to compensate you for them, and are under no obligation to keep
                                them confidential.
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <h4>Updating These Terms</h4>
                            <p>
                                We may change our Service and policies, and we may need to make changes to these Terms
                                so that they accurately reflect our Service and policies. Unless otherwise required by law, we
                                will notify you (for example, through our Service) at least 30 days before we make changes to
                                these Terms and give you an opportunity to review them before they go into effect. Then, if
                                you continue to use the Service, you will be bound by the updated Terms. If you do not want
                                to agree to these or any updated Terms, you can delete your account. 
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            Revised: 20th November 2023
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
            <Footer />
        </ResponsiveContainer>
    );
}