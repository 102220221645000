import { httpsCallable } from "@firebase/functions";
import { functions } from "./firebase";
import { BusinessProfileDataWithRole } from "../models/models";

export async function getBusinessProfileFromUrl(urlBasename: string) {
    const fun = httpsCallable(functions, "getBusinessProfile");
    const resp: any = (await fun({ urlBasename: urlBasename })).data;
    if (resp.status !== "success") return undefined;
    return resp.data;
}

export async function getBusinessProfileStoreId(business_id: string) {
    const fun = httpsCallable(functions, "getBusinessProfile");
    const resp: any = (await fun({ businessId: business_id })).data;
    if (resp.status !== "success") return undefined;
    return resp.data;
}

export async function getBusinessFeedPosts(store_id: string, lastObject?: any): Promise<[any[], any]> {
    const fun = httpsCallable(functions, "getBusinessFeedPosts");
    const resp: any = (await fun({ store_id: store_id, lastObject: lastObject })).data;
    if (resp.status !== "success") return [[], undefined];
    return [resp.data.posts as any[], resp.data.posts[resp.data.posts.length - 1] as any];
}

export async function getBusinessShop(store_id: string) {
    const fun = httpsCallable(functions, "getBusinessShop");
    const resp: any = (await fun({ business_id: store_id })).data;
    if (resp.status !== "success") return [];
    return resp.data;
}

export async function getBusinessUrlBasename(business_id: string) {
    const fun = httpsCallable(functions, "getBusinessUrlBasename");
    const resp: any = (await fun({ business_id: business_id })).data;
    if (resp.status !== "success") return undefined;
    return resp.data;
}

export async function getBusinessFollowers(business_id: string){
    const fun = httpsCallable(functions, "getBusinessFollowers");
    const resp: any = (await fun({ business_id: business_id })).data;
    if (resp.status !== "success") throw new Error("Failed to get followers");  
    return resp.data as {followers: number, isFollowing?: boolean};
}

export async function unfollowBusiness(business_id: string){
    const fun = httpsCallable(functions, "unfollowBusiness");
    const resp: any = (await fun({ business_id: business_id })).data;
    if (resp.status !== "success") throw new Error("Failed to unfollow business");
    return resp.data;
}

export async function followBusiness(business_id: string){
    const fun = httpsCallable(functions, "followBusiness");
    const resp: any = (await fun({ business_id: business_id })).data;
    if (resp.status !== "success") throw new Error("Failed to follow business");
    return resp.data;
}

export async function getAffiliatedBusinesses(){
    const fun = httpsCallable(functions, "fetchRegisteredAffiliatedBusinesses");
    const resp: any = (await fun()).data;
    if (resp.status !== "success") throw new Error("Failed to get affiliated businesses");
    return resp.data as BusinessProfileDataWithRole[];
}