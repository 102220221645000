import {
    Container, Menu, Segment,
    Sidebar, Icon, Header, Sticky,
} from 'semantic-ui-react';
import { DefaultHeader } from '../components/DefaultHeader';
import { ReactNode, useRef, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { appName } from '../common/constant';
import { Media } from '../storage/Media';
import { AppleStoreComponent } from './AppleStoreComponent';
import { GoogleStoreComponent } from './GoogleStoreComponent';
import { SearchComponent } from './SearchComponents';
import { SocialMediaLinks } from './SocialMediaLinks';

function DeskopContainer({ children }: { children: ReactNode }) {
    const [inView, setInView] = useState(false);
    const contextRef = useRef(null);

    return (
        <Media greaterThan='mobile'>
            <div ref={contextRef}>
                <InView onChange={() => setInView(!inView)}>
                    <Sticky context={contextRef}>
                        <Segment
                            inverted
                            textAlign='center'
                            style={{ padding: '0.5em 0em' }}
                            vertical
                        >
                            <DefaultHeader />
                        </Segment>
                    </Sticky>
                </InView>
                {children}
            </div>
        </Media>
    );
}

function MobileContainer({ children }: { children: ReactNode }) {
    const [sideBarOpened, setSideBarOpened] = useState(false);
    return (
        <Media at='mobile'>
            <Sidebar.Pushable
                style={{ height: 'auto' }}
            >
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    style={{ width: '90vw' }}
                    direction='right'
                    inverted
                    onHide={() => setSideBarOpened(false)}
                    vertical
                    visible={sideBarOpened}
                >
                    <Segment basic style={{ backgroundColor: 'transparent' }}>
                        <Header as={'h1'} style={{ textColor: 'white', color: 'white' }}>Chaguoo</Header>
                    </Segment>
                    <DefaultHeader mobile />
                    <Segment basic style={{ backgroundColor: 'transparent', width: '100%' }}>
                        <AppleStoreComponent theme='dark' />
                        <div style={{ height: '2vh' }}></div>
                        <GoogleStoreComponent theme='dark' />
                    </Segment>
                    <Segment basic style={{ backgroundColor: 'transparent', width: '100%' }}>
                        <SocialMediaLinks />
                    </Segment>
                </Sidebar>
                <Sidebar.Pusher dimmed={sideBarOpened}>
                    <Segment
                        inverted
                        textAlign='center'
                        style={{ padding: '0.5em 0em' }}
                        vertical
                    >
                        <Container>
                            <Menu inverted pointing secondary size='large'>
                                <Menu.Item as='a' header href="/" position='right'>
                                    {appName}
                                </Menu.Item>
                                <SearchComponent />
                                <Menu.Item onClick={() => setSideBarOpened(true)}>
                                    <Icon name='sidebar' />
                                </Menu.Item>
                            </Menu>
                        </Container>
                    </Segment>
                    {children}
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </Media>
    );
}

export function ResponsiveContainer({ children }: { children: ReactNode }) {
    return (
        <>
            <DeskopContainer>{children}</DeskopContainer>
            <MobileContainer>{children}</MobileContainer>
        </>
    );
}