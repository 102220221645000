import {
    getAuth, signInWithPopup, GoogleAuthProvider,
    setPersistence, browserLocalPersistence, signOut, onAuthStateChanged,
    signInWithEmailAndPassword, signInWithPhoneNumber, OAuthProvider,
    createUserWithEmailAndPassword,
} from "firebase/auth";

import { v4 as uuid_v4 } from "uuid";
import { auth, db } from "./firebase";
import {
    collection, addDoc,
} from 'firebase/firestore';
import { SupportRequest } from "../models/models";
import * as Sentry from "@sentry/react";

const googleProvider = new GoogleAuthProvider();
const supportRequestCollectionRef = collection(db, "supportRequests");

(async () => {
    await setPersistence(auth, browserLocalPersistence);
})();

export async function onAuthStateChangedWrapper() {
    return onAuthStateChanged(auth, (user) => user);
}

export async function loginWithGoogleFirebase() {
    return setPersistence(auth, browserLocalPersistence)
        .then(() => signInWithPopup(auth, googleProvider))
        .catch((error) => {
            Sentry.captureException(error);
            throw error;
        });
}

export async function loginWithAppleFirebase() {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    const auth = getAuth();
    signInWithPopup(auth, provider);
}

export async function logout() {
    return setPersistence(auth, browserLocalPersistence)
        .then(() => signOut(auth));
}

export async function logInWithEmailAndPassword(email: string, password: string) {
    return setPersistence(auth, browserLocalPersistence)
        .then(() => signInWithEmailAndPassword(auth, email, password));
}

export async function signUpWithEmailAndPassword(email: string, password: string) {
    return setPersistence(auth, browserLocalPersistence)
        .then(() => createUserWithEmailAndPassword(auth, email, password));
}

export async function loginWithPhoneNumber(phoneNumber: string, recaptchaVerifier: any) {
    return signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);
}

export async function submitSupportRequest(data: SupportRequest): Promise<string> {
    const request_id = uuid_v4();
    await addDoc(
        supportRequestCollectionRef,
        { ...data, request_id: request_id }
    );
    return "A support request has been saved, request ID : " + request_id;
}
