import React from 'react';
import { Container, Header, Segment } from 'semantic-ui-react';
import { ResponsiveContainer } from '../components/DefaultResponsiveContainer';
import { Helmet } from 'react-helmet-async';
import { Footer } from '../components/Footer';

const AboutPage: React.FC = () => {

    return (
        <ResponsiveContainer>
            <Helmet>
                <title>Chaguoo - About</title>
                <meta name="description" content="Get to know our driving force and the team." />
                <meta name="keywords" content="Chaguoo, Chaguoo team, Jean aime MBINIMPAYE, Vany Ingenzi, Leonce MPAWINEZA, Rwanda Chaguoo, Chaguoo Team" />
                <meta property="og:type" content="website" />
            </Helmet>
            <Segment basic>
                <Header as="h2">History</Header>
                <Container textAlign='left'>
                    <p>
                        Chaguoo was founded in 2023 with the aim of improving efficiency in African markets by digitizing marketplaces in Africa in a user-friendly way and
                        by using business intelligence. This allows consumers to easily find quality African-made products and services from reliable sources.
                        Furthermore, registered African (Rwandan) businesses can open online shops and reach out to both local and international markets using Chaguoo apps.
                    </p>
                </Container>
                <Header as="h2">Mission</Header>
                <Container textAlign='left'>
                    <p>
                        On a mission to help Rwandan brands become a success story through digitization and use of business intelligence.
                    </p>
                </Container>
                <Header as="h2">Vision</Header>
                <Container textAlign='left'>
                    <p>
                        To make entrepreneurship a dignified profession in Africa and a tool for economic self-development.
                    </p>
                </Container>
                <Header as="h2">Values</Header>
                <Container textAlign='left'>
                    <p>
                        Ethics in Business
                    </p>
                    <p>
                        Competence and Professionalism
                    </p>
                    <p>
                        Market-Driven Innovation and Creativity
                    </p>
                </Container>
                <Header as="h2">Administration</Header>
                <Container textAlign='left'>
                    <p>
                        Vany Ingenzi is the acting C.E.O. of Chaguoo Ltd. Chaguoo was found by Leonce MPAWINEZA & co-founded by Jean Aimé MBINIMPAYE and Vany INGENZI in 2023.
                        Chaguoo LTD is  registered as a business @Rwanda development Board and a tax payer @Rwanda Revenue Authority. For more information
                        and questions or suggestions contact us at info@chaguoo.com or Chaguoo page on linked-in.
                    </p>
                </Container>
                {/*<Header as="h2">Our Team</Header>
            <Container>
                <Card.Group centered>
                    {teamMembers.map((member, index) => (
                    <Card key={index}>
                        <Card.Content>
                        <Card.Header>{member.name}</Card.Header>
                        <Card.Meta>{member.role}</Card.Meta>
                        </Card.Content>
                    </Card>
                    ))}
                </Card.Group>
                    </Container>*/}
            </Segment>
            <Footer />
        </ResponsiveContainer>
    );
};

export default AboutPage;