import { memo } from "react";
import { Button, Container, Grid, Icon, Label, Placeholder } from "semantic-ui-react";
import MediaSlider from "../MediaSlider";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LikeButton } from "./LikeButton";
import { PostProfileHeader } from "./PostProfileHeader";

const MAX_CHARACTERS = 100;

function CollapsingText({ data, collapseText }: any) {
    if (collapseText && data.content.text && data.content.text.length > MAX_CHARACTERS) {
        return (
            <div style={{ textAlign: 'left' }}>
                <Container style={{ flexDirection: 'row' }}>
                    <p style={{ whiteSpace: 'pre-wrap', marginBottom: 10}}>
                        {data.content.text.slice(0, MAX_CHARACTERS) + '...'}
                    </p>
                    <Link
                        to={`/post/${data.id}`}
                        state={{ post: data }}
                    >
                        <p style={{ color: 'blue', cursor: 'pointer' }}>Read more</p>
                    </Link>
                </Container>
            </div>
        );
    }
    return (
        <div style={{ textAlign: 'left' }}>
            {data.content.text
                ? (
                    <Container style={{ marginBottom: 10 }}>
                        <p style={{ whiteSpace: 'pre-wrap' }}>{data.content.text}</p>
                    </Container>
                )
                : null}
        </div>
    );
}

function CommentButton({ data }: { data: any }) {
    const navigate = useNavigate();
    return (
        <Button
            as='div'
            labelPosition='right'
            onClick={() => {
                navigate(`/post/${data.id}`,
                    { state: { post: data, previousLocation: window.location.pathname } }
                );
            }}
        >
            <Button icon>
                <Icon name="comment" />
                Comment
            </Button>
            <Label as='a' basic pointing='left'>
                {data.commentsData?.nbComments || 0}
            </Label>
        </Button>
    );
}

function PostProfileHeaderPlaceholder() {
    return (
        <Grid stretched>
            <Grid.Row stretched>
                <Grid.Column style={{ width: 'fit-content' }} stretched>
                    <Placeholder>
                        <Placeholder.Image square style={{ width: 50, height: 50, borderRadius: 25 }} />
                    </Placeholder>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

interface PostProps {
    data: any;
    collapseText?: boolean;
}

function InnerPost({ data, collapseText }: PostProps) {
    const location = useLocation();

    return (
        <Grid style={{ backgroundColor: "white", borderRadius: 10, marginTop: 10, marginBottom: 10 }} >
            <Grid.Row>
                <Grid.Column>
                    <PostProfileHeader
                        name={data.store_data.name}
                        image={data.store_data.image}
                        posted={data.posted}
                        store_id={data.store_id}
                        url_basename={data.store_data.url_basename}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched>
                <Grid.Column stretched>
                    <CollapsingText data={data} collapseText={collapseText} />
                    <Link
                        to={`/post/${data.id}`}
                        state={{ previousLocation: location, post: data }}
                    >
                        {data.content.media
                            ? <MediaSlider cover remoteFiles={data.content.media} />
                            : null}
                    </Link>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Container>
                    <LikeButton data={data} />
                    <CommentButton data={data} />
                </Container>
            </Grid.Row>
        </Grid>
    );
}

export const Post = memo(InnerPost);

export function PostPlaceholder() {
    return (
        <Grid style={{ backgroundColor: "white", borderRadius: 10, marginTop: 10, marginBottom: 10 }}>
            <Grid.Row>
                <Grid.Column>
                    <PostProfileHeaderPlaceholder />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched>
                <Grid.Column stretched>
                    <Placeholder>
                        <Placeholder.Paragraph>
                            <Placeholder.Line length='full' />
                            <Placeholder.Line length='full' />
                            <Placeholder.Line length='full' />
                        </Placeholder.Paragraph>
                        <Placeholder.Paragraph>
                            <Placeholder.Image square style={{ marginTop: 20 }} />
                        </Placeholder.Paragraph>
                    </Placeholder>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}