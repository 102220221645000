import { Container, Divider, Grid, Header, Icon, List, Segment, Menu } from "semantic-ui-react";
import { ResponsiveContainer } from "../components/DefaultResponsiveContainer";
import { Outlet, useNavigate } from "react-router-dom";
import { Index, useHits, useSearchBox } from "react-instantsearch";
import { AlgoliaWrapper } from "../components/SearchComponents";
import { Footer } from "../components/Footer";
import { Media } from "../storage/Media";


function MobileFilters() {
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search);
    const q = queryParameters.get("q");

    return (
        <Media at="mobile">
            <Segment basic>
                <Menu >
                    <Menu.Item
                        name="Businesses"
                        onClick={() => navigate("/search/businesses/?q=" + (q ? encodeURIComponent(q) : ""))}
                        active={window.location.pathname.startsWith("/search/businesses")}
                    />
                    <Menu.Item
                        name="Products/Services"
                        onClick={() => navigate("/search/products/?q=" + (q ? encodeURIComponent(q) : ""))}
                        active={window.location.pathname.startsWith("/search/products/")}
                    />
                </Menu>
            </Segment>
        </Media>
    );
}

function TabletAndDesktopFilters() {
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search);
    const q = queryParameters.get("q");

    return (
        <Media greaterThan="mobile">
            <Segment
                basic
                style={{ backgroundColor: 'white', borderRadius: 10, height: '80vh', overflowY: 'auto' }}
            >
                <Header textAlign="left" as={'h2'}>Filters</Header>
                <Divider />
                <List
                    size="huge"
                    style={{ backgroundColor: 'white', padding: 10, borderRadius: 4, textAlign: 'left' }}
                >
                    <List.Item
                        className={"cursor-pointer" + (window.location.pathname.startsWith("/search/businesses") ? " filter-selected" : "")}
                        onClick={() => navigate("/search/businesses/?q=" + (q ? encodeURIComponent(q) : ""))}
                        style={{ marginTop: 4, borderRadius: 10, padding: 6 }}
                    >
                        <Icon name="building" />
                        <List.Content>
                            <List.Header>
                                Businesses
                            </List.Header>
                        </List.Content>
                    </List.Item>
                    <List.Item
                        style={{ marginTop: 4, borderRadius: 10, padding: 6 }}
                        className={"cursor-pointer" + (window.location.pathname.startsWith("/search/products/") ? " filter-selected" : "")}
                        onClick={() => navigate("/search/products/?q=" + (q ? encodeURIComponent(q) : ""))}
                    >
                        <Icon name="dolly" />
                        <List.Content>
                            <List.Header>
                                Products / Services
                            </List.Header>
                        </List.Content>
                    </List.Item>
                </List>
            </Segment>
        </Media>
    )
}

function Filters() {
    return (
        <>
            <TabletAndDesktopFilters />
            <MobileFilters />
        </>
    )
}

function BusinessHit({ data }: any) {
    let description = data.business_sector || '';
    description = description.length > 100 ? description.slice(0, 100) + "..." : description;
    const navigate = useNavigate();

    return (
        <List.Item
            className={"search-hit cursor-pointer"}
            style={{ textAlign: 'left', padding: 10, borderRadius: 10, marginBottom: 4 }}
            onClick={() => navigate(`/redictToBusinessFromId/${data.store_id}`)}
        >
            <List.Content>
                <List.Header>{data.store_name}</List.Header>
                <List.Description>{description}</List.Description>
            </List.Content>
        </List.Item>
    )
}

function InnerBusinessesSearchResultsHolder({ ...props }) {
    const { hits } = useHits(props);
    const { refine } = useSearchBox(props);

    const queryParameters = new URLSearchParams(window.location.search);
    const q = queryParameters.get("q");

    if (q) refine(q);

    return (
        <Segment
            basic style={{ backgroundColor: 'white', borderRadius: 10, height: '80vh', overflowY: 'auto' }}
        >
            <Header textAlign="left" as={'h2'}>Business Results</Header>
            <Divider />
            <Index indexName="shops">
                <List>
                    {hits.map((item: any, index: number) => <BusinessHit key={index} data={item} />)}
                </List>
                {hits && hits.length === 0 ? <p>No business found for : {q}</p> : null}
            </Index>
        </Segment>
    )
}

export function BusinessesSearchResultsHolder({ ...props }) {
    return (
        <AlgoliaWrapper index="shops">
            <InnerBusinessesSearchResultsHolder {...props} />
        </AlgoliaWrapper>
    )
}

function ProductHit({ data }: any) {
    let description = data.description || '';
    description = description.length > 100 ? description.slice(0, 100) + "..." : description;
    const navigate = useNavigate();

    return (
        <List.Item
            className={"search-hit cursor-pointer"}
            style={{ textAlign: 'left', padding: 10, borderRadius: 10, marginBottom: 4 }}
            onClick={() => navigate(`/product/${data.id}`)}
        >
            <List.Content>
                <List.Header>{data.product_name}</List.Header>
                <List.Description>{description}</List.Description>
            </List.Content>
        </List.Item>
    )
}

function InnerProductsSearchResultsHolder({ ...props }) {
    const { hits } = useHits(props);
    const { refine } = useSearchBox(props);
    const queryParameters = new URLSearchParams(window.location.search);
    const q = queryParameters.get("q");

    if (q) refine(q);

    return (
        <Segment
            basic style={{ backgroundColor: 'white', borderRadius: 10, height: '80vh', overflowY: 'auto' }}
        >
            <Header textAlign="left" as={'h2'}>Products / Services</Header>
            <Divider />
            <List>
                {hits.map((item: any, index: number) => <ProductHit key={index} data={item} />)}
            </List>
            {hits && hits.length === 0 ? <p>No product found for : {q}</p> : null}
        </Segment>
    )
}

export function ProductsSearchResultsHolder({ ...props }) {
    return (
        <AlgoliaWrapper index="products">
            <InnerProductsSearchResultsHolder {...props} />
        </AlgoliaWrapper>
    )
}

function MobileVersoin() {
    return (
        <Media at="mobile">
            <Grid columns={3} style={{ minWidth: 300, marginTop: 10 }}>
                <Grid.Row stretched>
                    <Grid.Column floated="right" width={"16"}>
                        <Filters />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={"16"}>
                        <Outlet />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Media>
    )
}

function TabletAndDesktopVersion() {
    return (
        <Media greaterThan="mobile">
            <Grid columns={3} style={{ minWidth: 300, marginTop: 10 }}>
                <Grid.Row stretched>
                    <Grid.Column floated="right" width={"6"}>
                        <Filters />
                    </Grid.Column>
                    <Grid.Column width={"8"}>
                        <Outlet />
                    </Grid.Column>
                    <Grid.Column floated="left" width={"2"}>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Media>
    )
}

export function SearchPage() {
    return (
        <ResponsiveContainer>
            <MobileVersoin />
            <TabletAndDesktopVersion />
            <Container style={{ marginTop: 20, width: 0 }} />
            <Footer />
        </ResponsiveContainer>
    )
}