import { ChangeEvent, useState } from "react";
import { Button, Form, Grid, Header, Message, Popup, Segment, TextArea } from "semantic-ui-react";
import { ResponsiveContainer } from "../components/DefaultResponsiveContainer";
import { useGeneralContext } from "../storage/context/GeneralContext";
import { ConfirmationPopup } from "../components/ConfirmationPopup";
import { requestAccountDeletion } from "../api/profileManagement";

export default function DeleteAccountPage() {
    const {currentUser} = useGeneralContext();
    const [value, setValue]     = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError]     = useState("");
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setValue(event.target.value);
    }

    const handleSubmit = () => {
        setError((prev) => "");
        setSuccess((prev) => "");
        setLoading((prev) => true);
        if (value.length === 0){
            return setError((prev) => "For technical and legal reason the reason has to be specified.");
        }
        if (currentUser){
            requestAccountDeletion(value)
            .then((msg) => {setSuccess((prev) => msg); setLoading((prev) => false);})
            .catch((err) => {setError((prev) => err); setLoading((prev) => false);});
        }
    }

    return (
        <ResponsiveContainer>
            <ConfirmationPopup
                open={isPopupOpen}
                header="Delete Account"
                text="Are you sure you want to delete your account?"
                onConfirm={() => {setIsPopupOpen(false); handleSubmit(); }}
                onCancel={() => setIsPopupOpen(false)}
                isNegative
            />
            <Header as='h3' style={{ fontSize: '2em'}}>
                Delete Account
            </Header>
            <Segment basic>
                <Grid centered verticalAlign='middle'>
                    <Grid.Column width={12}>
                        <Form 
                            onSubmit={handleSubmit} 
                            loading={loading} 
                        >
                            {success.length > 0 ?
                             <Message success visible={success.length > 0}>
                                {success}
                            </Message> : null}
                            {error.length > 0 ?
                             <Message negative visible={error.length > 0}>
                                {error}
                            </Message> : null}
                            <Form.Field>
                                <label>Reason for deletion</label>
                                <TextArea 
                                    placeholder='We are sorry to see you go. Why are you deleting your account' 
                                    value={value} 
                                    onChange={handleChange} 
                                />
                            </Form.Field>
                            <Button 
                                onClick={(event) => {event.preventDefault(); setIsPopupOpen(true)}}
                                type='submit' 
                                color="red" 
                                disabled={loading || value.length === 0}
                            >
                                Delete Account
                            </Button>
                        </Form>
                    </Grid.Column>
                    <Popup
                        content={<Button onClick={() => setIsPopupOpen(!isPopupOpen)}>Close</Button>}
                        on="click"
                        open={isPopupOpen}
                        onOpen={() => setIsPopupOpen(!isPopupOpen)}
                    />
                </Grid>
            </Segment>
        </ResponsiveContainer>
    );
}