import moment from "moment";

export function removeFirebasePrefix(errorMsg: string): string {
    const bracketIndex = errorMsg.indexOf(']');
    if (bracketIndex === -1) {
        return errorMsg;
    }
    return errorMsg.slice(bracketIndex + 1).trim();
}

export function getTimeStringFromFirebaseTimestamp(timestampObject: { _seconds: number, _nanoseconds: number }): string {
    const timestamp = moment.unix(timestampObject._seconds);
    const currentTime = moment(); // current time
    const differenceInHours = currentTime.diff(timestamp, 'hours');
    if (differenceInHours < 24) {
        return timestamp.fromNow(); // Display as "hours ago"
    } else {
        return timestamp.format('MMMM DD YYYY'); // Display as "Month Date Year"
    }
}

export async function encodeImageInBase64(file: File): Promise<string> {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
        reader.onloadend = () => {
            resolve(reader.result as string);
        }
        reader.onerror = () => {
            reject(reader.error);
        }
    })
}