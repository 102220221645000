import { createContext, useContext, useState } from "react";

interface MessagingPageContextProps {
    currentChat: any;
    setCurrentChat: (any: any) => void;
}

const MessagingPageContext = createContext<MessagingPageContextProps>({
    currentChat: undefined, 
    setCurrentChat: (any: any) => {}
});

export function MessagingPageContextProvider({children} : {children: React.ReactNode}){
    const [currentChat, setCurrentChat] = useState<any>(undefined);

    const value = {
        currentChat: currentChat, 
        setCurrentChat: setCurrentChat
    };

    return (
        <MessagingPageContext.Provider value={value}>
            {children}
        </MessagingPageContext.Provider>
    );
}

export const useMessagingPageContext = () => useContext(MessagingPageContext);