import { Divider, Grid, Segment, Tab } from "semantic-ui-react";
import { ResponsiveContainer } from "../components/DefaultResponsiveContainer";
import { InboxHolder } from "../components/messaging/InboxHolder";
import { Outlet } from "react-router-dom";
import { Media } from "../storage/Media";
import { MessagingPageContextProvider, useMessagingPageContext } from "../storage/context/MessagingPageContext";
import { ChatDisplayMobileHeader } from "../components/messaging/ChatDisplay";

function DesktopVersion(){
    return(
        <Media greaterThan="mobile">
            <Grid columns={3} style={{backgroundColor: "#f5f5f5", minWidth: 300, marginTop: 10}}>
                <Grid.Row stretched>
                    <Grid.Column floated="right" width={"4"}>
                        <InboxHolder />
                    </Grid.Column>
                    <Grid.Column width={"6"}>
                        <Outlet/>
                    </Grid.Column>
                    <Grid.Column floated="left" width={"2"}>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Media>
    );
}

function MobileVersionOutlet(){
    const { currentChat } = useMessagingPageContext();

    return (
        <Segment>
            {currentChat ? <ChatDisplayMobileHeader key={'kurred'} data={currentChat} /> : null}
            <Divider />
            <Outlet />
        </Segment>
    );
}

function MobileVersion(){
    const panes = [
        {
            menuItem: "Inbox",
            render: () => <InboxHolder />
        },
        {
            menuItem: "Messages",
            render: () => <MobileVersionOutlet />
        }
    ];

    return (
        <Media at="mobile">
            <Tab panes={panes} renderActiveOnly/>
        </Media>
    );
}

export function MessagesPage(){
    return (
        <MessagingPageContextProvider>
            <ResponsiveContainer>
                <DesktopVersion />
                <MobileVersion />
            </ResponsiveContainer>
        </MessagingPageContextProvider>
    )
}