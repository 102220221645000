import { memo, useCallback, useEffect, useState } from "react"
import { Grid, Segment } from "semantic-ui-react";
import { Post, PostPlaceholder } from "./post/Post";
import { SimpleErrorMessage } from "./ErrorMessages";
import { useHomeFeedContext } from "../storage/context/HomeFeedContext";
import { queryPostsCollection } from "../api/feed";
import _ from 'lodash';
import { Media } from "../storage/Media";

const InnerFeed = ({ style }: any) => {
    const [data, setData] = useState<any[]>([]);
    const [lastObject, setLastObject] = useState<any>(undefined);
    const { handledScrolled, setHandledScrolled } = useHomeFeedContext();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | undefined>(undefined);

    const getData = useCallback(async (lastObject: any, signal: AbortSignal) => {
        if (loading) return;
        setLoading(() => true);
        try {
            if (lastObject === undefined) {
                const [posts, newLastObject] = await queryPostsCollection();
                if (signal.aborted) return;
                setData(() => posts);
                if (signal.aborted) return;
                setLastObject(() => newLastObject);
            } else {
                const [posts, newLastObject] = await queryPostsCollection(lastObject);
                if (signal.aborted) return;
                setData((prev) => [...prev, ...posts]);
                if (signal.aborted) return;
                setLastObject(() => newLastObject);
            }
        } catch (error) {
            if (signal.aborted) return;
            setError(() => error as Error);
        } finally {
            if (signal.aborted) return;
            setLoading(() => false);
        }
    }, [loading]);

    const onHandleScroll = useCallback(async (documentHeight: number) => {
        const abortController = new AbortController();
        if (handledScrolled[documentHeight]) return; // Already handled
        setHandledScrolled((prev: any) => ({ ...prev, [documentHeight]: true })); // Mark as handled
        await getData(lastObject, abortController.signal);
        return () => {
            abortController.abort();
        }
    }, [lastObject, handledScrolled, setHandledScrolled, getData]);

    useEffect(() => {
        const abortController = new AbortController();
        getData(undefined, abortController.signal);
        return () => {
            abortController.abort();
        }
    }, []); // Runs only once on mount

    useEffect(() => {
        const handleScroll = () => {
            const scrollContainer = document.getElementById('scroll-feed-container');
            const containerHeight = scrollContainer?.clientHeight || 0;
            const isBottom = (window.innerHeight + document.documentElement.scrollTop) >= containerHeight;
            if (isBottom) {
                onHandleScroll(containerHeight);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [onHandleScroll]);

    if (error) {
        return (
            <SimpleErrorMessage
                style={style}
                error={error.message} 
                hint="Make sure you are connected to the internet and retry later."
            />
        );
    }

    if (loading) {
        return (
            <Segment basic style={{ ...style, marginTop: 20, width: '100%' }} id="scroll-feed-container">
                <Grid relaxed stackable>
                    <Grid.Column mobile={16} tablet={8} computer={4}>
                        <PostPlaceholder />
                    </Grid.Column>
                    {_.times(8, (i) => (
                        <Grid.Column key={i} mobile={16} tablet={8} computer={4}>
                            <PostPlaceholder />
                        </Grid.Column>
                    ))}
                </Grid>
            </Segment>
        );
    }

    return (
        <>
            <Media at="mobile">
                <Segment basic style={style} id="scroll-feed-container">
                    <Grid relaxed stackable>
                        {data.map((postData: any) => (
                            <Grid.Column key={postData.id} width={16}>
                                <Post
                                    key={postData.id}
                                    data={postData}
                                    collapseText
                                />
                            </Grid.Column>
                        ))}
                    </Grid>
                </Segment>
            </Media>
            <Media at="tablet">
                <Segment basic style={style} id="scroll-feed-container">
                    <Grid relaxed stackable>
                        {data.map((postData: any) => (
                            <Grid.Column key={postData.id} width={5}>
                                <Post
                                    key={postData.id}
                                    data={postData}
                                    collapseText
                                />
                            </Grid.Column>
                        ))}
                    </Grid>
                </Segment>
            </Media>
            <Media greaterThan="tablet">
                <Segment basic style={style} id="scroll-feed-container">
                    <Grid relaxed stackable>
                        {data.map((postData: any) => (
                            <Grid.Column key={postData.id} width={4}>
                                <Post
                                    key={postData.id}
                                    data={postData}
                                    collapseText
                                />
                            </Grid.Column>
                        ))}
                    </Grid>
                </Segment>
            </Media>
        </>
    );
}

export const FeedScrollView = memo(InnerFeed);