import { Button, Container, Image } from "semantic-ui-react";
import { CommentInput } from "./CommentInput";
import { useCallback, useEffect, useState } from "react";
import { ChatUser, PostData } from "../../models/models";
import { useGeneralContext } from "../../storage/context/GeneralContext";
import { handleRequiredAuth } from "../../common/utils";
import { useNavigate } from "react-router-dom";
import { commentOnPost, deleteComment, getUserProfileInfo } from "../../api/feed";
import * as Sentry from "@sentry/react";
import { missingImage, missingProfileImage } from "../../common/constant";
import { getBusinessProfileStoreId } from "../../api/business";
import { ConfirmationPopup } from "../ConfirmationPopup";

function CommentProfileHeaderBusiness({ user }: { user: ChatUser }) {
    const [name, displayName] = useState("Loading ...");
    const [image, imageSrc] = useState(missingImage);

    useEffect(() => {
        const abortController = new AbortController();
        const getData = async () => {
            try {
                if (!user.id) throw new Error("Invalid user id");
                const data = await getBusinessProfileStoreId(user.id);
                if (abortController.signal.aborted) return;
                displayName(data.name || "Unnamed Business");
                imageSrc(data.image || missingProfileImage);
            } catch (error) {
                Sentry.captureException(error);
                throw error;
            }
        }
        getData();
        return () => {
            abortController.abort();
        }
    }, [user]);
    return (
        <Container style={{ display: 'flex', flexDirection: 'row' }}>
            <Image src={image} alt="profile" style={{ width: 40, height: 40, borderRadius: 15, marginRight: 10 }} />
            <p style={{ fontWeight: 'bold' }}>{name}</p>
        </Container>
    );
}

function CommentProfileHeaderUser({ user }: { user: ChatUser }) {
    const [name, displayName] = useState("Loading ...");
    const [image, imageSrc] = useState(undefined);

    useEffect(() => {
        const abortController = new AbortController();
        const getData = async () => {
            try {
                if (!user.id) throw new Error("Invalid user id");
                const data = await getUserProfileInfo(user.id);
                if (abortController.signal.aborted) return;
                displayName(data.displayName || "Unnamed User");
                imageSrc(data.image || undefined);
            } catch (error) {
                Sentry.captureException(error);
                throw error;
            }
        }
        getData();
        return () => {
            abortController.abort();
        }
    }, [user]);
    return (
        <Container style={{ display: 'flex', flexDirection: 'row' }}>
            {image ?
                <Image src={image} alt="profile" style={{ width: 40, height: 40, borderRadius: 15, marginRight: 10 }} />
                : null}
            <p style={{ fontWeight: 'bold' }}>{name}</p>
        </Container>
    );
}

function CommentProfileHeader({ user }: { user: ChatUser }) {
    if (!user || !user.type) {
        return (
            null
        );
    }
    if (user.type === 'user') {
        return (
            <CommentProfileHeaderUser user={user} />
        );
    } else if (user.type === 'store') {
        return (
            <CommentProfileHeaderBusiness user={user} />
        );
    }
    return (
        null
    );
}

function DeleteButton({ post_id, comment_id, sender }: { post_id: string, comment_id: string, sender: ChatUser }) {
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const { affiliatedBusinesses, currentUser } = useGeneralContext();
    const onConfirmedDelete = useCallback(async () => {
        try {
            setOpenModal(() => false);
            setLoading(() => true);
            if (!comment_id) throw new Error("Invalid comment id");
            if (!sender.id) throw new Error("Invalid sender id");
            await deleteComment(post_id, comment_id, sender);
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        } finally {
            setLoading(() => false);
        }
    }, [comment_id, sender, post_id]);

    const button = (
        <>
            <Button onClick={() => setOpenModal(() => true)} loading={loading} disabled={loading} style={{ width: 'fit-content', marginTop: 10, marginBottom: 10 }} color='red' size='mini'>
                Delete
            </Button>
            <ConfirmationPopup
                open={openModal}
                header='Delete Comment'
                text='Are you sure you want to delete this comment?'
                isNegative={true}
                onConfirm={onConfirmedDelete}
                onCancel={() => setOpenModal(() => false)}
            />
        </>
    );

    if (!sender) {
        return null;
    } else if (sender.type === 'store' && affiliatedBusinesses.map(b => b.store_id).includes(sender.id)) {
        return button;
    } else if (sender.type === 'user' && sender.id === currentUser?.uid) {
        return button;
    } else {
        return null;
    }
}

function CommentsView({ post_id, commentsData }: { commentsData: any[], post_id: string }) {
    return (
        <Container style={{ marginBottom: 10 }}>
            {commentsData.map((comment: any) => (
                <div key={comment.id} style={{ justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginTop: 10, marginBottom: 10 }}>
                    <CommentProfileHeader user={comment.sender} />
                    <Container key={comment.id}>
                        <p style={{ textAlign: 'left' }}>{comment.content.text}</p>
                    </Container>
                    <DeleteButton comment_id={comment.id} sender={comment.sender} post_id={post_id} />
                </div>
            ))}
        </Container>
    );
}

export function PostCommentSection({ data, refreshPost }: { data: PostData, refreshPost: () => void }) {
    const comments = data.commentsData?.comments || [];
    const [nbComments, setNbComments] = useState(data.commentsData?.nbComments || 0);
    const { authenticated } = useGeneralContext();
    const navigate = useNavigate();

    const onComment = useCallback(async (user: ChatUser, msg: string) => {
        if (!authenticated) {
            return handleRequiredAuth(navigate, "You need to be authenticated to comment on a post.");
        }
        try {
            if (!data.id) throw new Error("Invalid post id");
            if (msg.length === 0) throw new Error("Invalid comment");
            if (user.type !== 'store' && user.type !== 'user') throw new Error("Invalid user type");
            await commentOnPost(data.id, msg, user);
            refreshPost();
            setNbComments(() => nbComments + 1);
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    }, [data, nbComments, authenticated, navigate, refreshPost]);

    return (
        <div style={{ width: '100%' }}>
            {comments.length === 0 ?
                <Container style={{ marginBottom: 20 }}>
                    <span>No comments</span>
                </Container> : <CommentsView commentsData={comments} post_id={data.id} />}
            <p style={{ textAlign: 'left', width: '100%' }}>
                {nbComments} {nbComments === 1 ? 'comment' : 'comments'}
            </p>
            <CommentInput onComment={onComment} />
        </div>
    );
}