import { Card, Grid, GridColumn, Icon, Image, Segment } from "semantic-ui-react";
import { getTimeStringFromFirebaseTimestamp } from "../../common/format";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { useMessagingPageContext } from "../../storage/context/MessagingPageContext";
import { onOpenUnreadConversation } from "../../api/messaging";

export function ChatDisplay({data}: any) {
    const navigate = useNavigate();
    const {chat_id} = useParams();
    const {currentChat, setCurrentChat} = useMessagingPageContext();

    useEffect(() => {
        if (chat_id === data.chat_id && (!currentChat  || (currentChat && currentChat.chat_id !== data.chat_id))) {
            setCurrentChat(data);
        }
    }, [chat_id, data, setCurrentChat, currentChat]);
    
    const onClick = useCallback(async () => {
        if (!data.chat_id) return
        if (data.unread)
            await onOpenUnreadConversation(data.chat_id);
        navigate(`/messages/${data.chat_id}`);
    }, [data, navigate]);

    if (data.membersProfile.length === 0) {
        return (
            <Grid textAlign="left">
                <Grid.Row>
                    <Grid.Column>
                        <p>Invalid Chat</p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }


    return (
        <Grid style={{overflowX: 'auto', borderRadius: 10, marginTop: 4, marginBottom: 4, marginLeft: 1, marginRight: 1}} 
            className={"chat-holder" + (chat_id === data.chat_id ? "-selected" : "")} 
            onClick={onClick}
        >
            <Grid.Row>
                <Grid.Column style={{width: 'auto', minWidth: 'fit-content'}}>
                    <Image
                        floated="left"
                        size="tiny"
                        src={data.membersProfile[0].image}
                    />
                </Grid.Column>
                <Grid.Column style={{width: 'auto', minWidth: 'fit-content'}} textAlign="left">
                    <p style={{fontSize: 18, fontWeight: (data.unread && chat_id !== data.chat_id) ? "bold" : undefined}}>
                        {data.membersProfile[0].displayName || data.membersProfile[0].name || "Unnamed"}
                    </p>
                    <p style={{fontSize: 10, fontWeight: (data.unread && chat_id !== data.chat_id) ? "bold" : undefined}}>
                        {getTimeStringFromFirebaseTimestamp(data.lastEventTimestamp)}
                    </p>
                </Grid.Column>
                {(data.unread && chat_id !== data.chat_id) ? 
                <GridColumn style={{width: 'auto', minWidth: 'fit-content'}} floated="right">
                    <Segment basic>
                        <Icon name="circle"/>
                    </Segment>
                </GridColumn> : null}
            </Grid.Row>
        </Grid>
    )
}

export function ChatDisplayMobileHeader({data}: any) {

    if (data.membersProfile.length === 0) {
        return (
            <Card>
                <Card.Content>
                    <Card.Header>Invalid Chat</Card.Header>
                </Card.Content>
            </Card>
        );
    }

    return (
        <Segment style={{width: '100%'}} basic className="chat-holder">
            <Image
                floated="left"
                size="mini"
                src={data.membersProfile[0].image}
            />
            <p>{data.membersProfile[0].displayName || data.membersProfile[0].name || "Unnamed"}</p>
        </Segment>
    )
}