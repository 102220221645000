import { createContext, useContext, useState } from "react";

export interface FilterInfos{
    minPrice?: number | undefined;
    maxPrice?: number | undefined;
    currency?: string;
}

type FilterInfosKey = keyof FilterInfos;

interface MarketplaceContextProps {
    filters: FilterInfos;
    updateFilters: (field: FilterInfosKey, value: any) => void;
}

const MarketplaceContext = createContext<MarketplaceContextProps>({
    filters: {}, 
    updateFilters: () => {}
});

export function MarketplaceContextProvider({children}: {children: React.ReactNode}){
    const [filters, setFilters] = useState<FilterInfos>({
        currency: 'RWF'
    });
    const updateFilters = (field: FilterInfosKey, value: any) => {
        setFilters((prev) => {
            return {
                ...prev,
                [field]: value
            }
        })
    }

    const value = {
        filters: filters,
        updateFilters: updateFilters
    }

    return (
        <MarketplaceContext.Provider value={value}>
            {children}
        </MarketplaceContext.Provider>
    )
}

export const useMarketplaceContext = () => useContext(MarketplaceContext);
