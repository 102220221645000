import { useState, createContext, ReactNode, useEffect, useContext, useCallback } from "react";
import { User } from "firebase/auth";
import { auth } from "../../api/firebase";
import { getProfileInfo } from "../../api/profileManagement";
import { notifyFCMToken } from "../../api/messaging";
import { BusinessProfileData, BusinessProfileDataWithRole, UserProfile } from "../../models/models";
import { getAffiliatedBusinesses } from "../../api/business";

interface GeneralContextType {
    currentUser: User | undefined | null;
    currentProfile: UserProfile;
    authenticated: boolean;
    affiliatedBusinesses: BusinessProfileData[];
    refreshProfile: () => void;
}

const GeneralContext = createContext<GeneralContextType>({
    currentUser: undefined,
    currentProfile: {},
    authenticated: false,
    affiliatedBusinesses: [],
    refreshProfile: () => { }
});

export const useGeneralContext = () => useContext(GeneralContext);

interface GeneralContextProviderProps {
    children?: ReactNode
}

export default function GeneralContextProvider({ children }: GeneralContextProviderProps) {
    const [user, setCurrentUser] = useState<User | undefined | null>(undefined);
    const [loading, setLoading] = useState(false);
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const [currentProfile, setCurrentProfile] = useState<UserProfile>({});
    const [affiliatedBusinesses, setAffiliatedBusinesses] = useState<BusinessProfileDataWithRole[]>([]);

    const value = {
        currentUser: user,
        currentProfile: currentProfile,
        authenticated: authenticated,
        refreshProfile: () => getProfileData(),
        affiliatedBusinesses: affiliatedBusinesses
    };

    const getProfileData = useCallback(async () => {
        if (!user || !user.uid) return;
        const data = await getProfileInfo();
        if (data) {
            setCurrentProfile(data);
        }
    }, [user, setCurrentProfile]);

    const updateFCMToken = useCallback(async (signal: AbortSignal) => {
        if (!user || !user.uid) return;
        if (signal.aborted) return;
        await notifyFCMToken();
    }, [user]);

    useEffect(() => {
        const abortController = new AbortController();
        if (abortController.signal.aborted) return;
        getProfileData();
        if (abortController.signal.aborted) return;
        updateFCMToken(abortController.signal);
        return () => abortController.abort();
    }, [user, getProfileData, setAuthenticated, updateFCMToken]);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setCurrentUser(user);
            setAuthenticated(!!user);
            setLoading(false);
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const abortController = new AbortController();
        const fetchAffiliatedBusiness = async (signal: AbortSignal) => {
            if (!user || !user.uid) {
                return setAffiliatedBusinesses(() => []);
            }
            const data = await getAffiliatedBusinesses();
            if (signal.aborted) return;
            setAffiliatedBusinesses(() => data);
        }
        fetchAffiliatedBusiness(abortController.signal);
        return () => abortController.abort();
    }, [user]);

    return (
        <GeneralContext.Provider value={value}>
            {!loading && children}
        </GeneralContext.Provider>
    );
}