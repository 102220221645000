import { memo } from "react";
import { GooglePlayButton } from "react-mobile-app-button";

function InnerGoogleStoreComponent({theme}: {theme: 'light' | 'dark'}) {
    const APKUrl =
        "https://play.google.com/store/apps/details?id=com.ingenzivany.chaguomobileapp&pcampaignid=web_share";
    return (
        <GooglePlayButton
            height={50}
            url={APKUrl}
            theme={theme}
            className={"google-store tp-m-1"}
        />
    );
};

export const GoogleStoreComponent = memo(InnerGoogleStoreComponent);