import { Button, Icon, Label } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import { useGeneralContext } from "../../storage/context/GeneralContext";
import { handleRequiredAuth } from "../../common/utils";
import { userLikeAPost, userUnlikeAPost } from "../../api/feed";

export function LikeButton({ data }: { data: any }) {
    const [liked, setLiked] = useState(data.likesData?.userLikes || false);
    const [nbLikes, setNbLikes] = useState(data.likesData?.nbLikes || 0);
    const [loading, setLoading] = useState(false);
    const { authenticated } = useGeneralContext();
    const navigate = useNavigate();

    const toggleLike = useCallback(async () => {
        if (!authenticated) {
            return handleRequiredAuth(navigate, "You need to be authenticated to like a post.");
        }
        setLoading(() => true);
        try {
            if (liked) {
                await userUnlikeAPost(data.id);
                setLiked(false);
                setNbLikes(nbLikes - 1);
            } else {
                await userLikeAPost(data.id);
                setLiked(true);
                setNbLikes(nbLikes + 1);
            }
        } finally {
            setLoading(() => false);
        }
    }, [liked, navigate, nbLikes, authenticated, data]);

    return (
        <Button as='div' labelPosition='right' disabled={loading} onClick={toggleLike} loading={loading}>
            <Button icon>
                <Icon name={liked ? "thumbs up" : "thumbs up outline"} />
                Like
            </Button>
            <Label as='a' basic pointing='left'>
                {nbLikes}
            </Label>
        </Button>
    );
}