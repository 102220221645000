import { useCallback, useEffect, useState } from "react";
import { Button, Container, Dropdown, Form, Grid, Icon, TextArea } from "semantic-ui-react";
import { useGeneralContext } from "../../storage/context/GeneralContext";
import { missingImage } from "../../common/constant";
import { ChatUser } from "../../models/models";
import * as Sentry from "@sentry/react";
import { Link, useNavigate } from "react-router-dom";
import { handleRequiredAuth } from "../../common/utils";

interface DropdownItem {
    key: string;
    value: string;
    text?: string;
    image?: { avatar: boolean, src: string }
}

function EntitySelector({ onChange }: { onChange: (v: ChatUser | undefined) => void }) {
    const { affiliatedBusinesses, currentProfile, currentUser } = useGeneralContext();
    const [options, setOptions] = useState<DropdownItem[]>([]);
    const [value, setValue] = useState<ChatUser | undefined>(undefined);

    const memoizedNotifyChange = useCallback(() => {
        onChange(value);
    }, [onChange, value]);

    useEffect(() => {
        memoizedNotifyChange();
    }, [value, memoizedNotifyChange]);

    useEffect(() => {
        const abortController = new AbortController();
        if (!currentUser) {
            setOptions([]);
            setValue(undefined);
            return;
        }
        let optionsToSet: DropdownItem[] = [];
        affiliatedBusinesses.forEach((businessProfile) => {
            let defaultObj = {
                key: businessProfile.store_id,
                text: businessProfile.name,
                value: JSON.stringify({ type: 'store', id: businessProfile.store_id }),
                image: {
                    avatar: true,
                    src: businessProfile.image || missingImage
                }
            };
            optionsToSet.push(defaultObj);
        });
        optionsToSet.push({
            key: currentProfile.displayName || "Unnamed User",
            text: currentProfile.displayName || "Unnamed User",
            value: JSON.stringify({ type: 'user', id: currentUser?.uid }),
            image: {
                avatar: true,
                src: currentProfile.image || missingImage
            },
        });
        if (abortController.signal.aborted) return;
        setOptions(() => optionsToSet);
        setValue(() => JSON.parse(optionsToSet[0].value) as ChatUser);
        return () => abortController.abort();
    }, [affiliatedBusinesses, currentProfile, currentUser]);

    if (options.length === 0) {
        return (
            <Link to="/login">
                <span>
                    Please login
                </span>
            </Link>
        );
    }

    return (
        <Dropdown
            inline
            options={options}
            lazyLoad
            value={JSON.stringify(value)}
            onChange={(e, { value }) => {
                setValue(() => JSON.parse(value as string) as ChatUser);
            }}
        />
    );
}

function InnerCommentInput({ onSendComment }: { onSendComment: (msg: string) => Promise<void> }) {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const sendClicked = useCallback(async () => {
        setLoading(() => true);
        try {
            await onSendComment(message);
            setMessage(() => '');
        } catch (err) {
            Sentry.captureException(err);
        }
        finally {
            setLoading(() => false);
        }
    }, [message, onSendComment]);

    return (
        <Container style={{ justifyContent: 'space-around', flexDirection: 'row', display: 'flex' }}>
            <Form style={{ width: '80%' }}>
                {loading ?
                    null
                    : <TextArea
                        style={{ resize: 'none' }}
                        rows={3}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />}
            </Form>
            <Button
                icon
                style={{ width: '10%', minWidth: 'fit-content' }}
                onClick={() => sendClicked()}
                disabled={loading || message.length === 0}
                primary
                loading={loading}
            >
                <Icon name="send" />
            </Button>
        </Container>
    );
}

export function CommentInput({ onComment }: { onComment: (user: ChatUser, msg: string) => Promise<void> }) {
    const [entity, setEntity] = useState<ChatUser | undefined>(undefined);
    const {authenticated} = useGeneralContext();
    const navigate = useNavigate();

    const handleOnComment = useCallback(async (msg: string) => {
        if (!authenticated) return handleRequiredAuth(navigate, "Login in order to comment");
        if (!entity) throw new Error('Entity not selected');
        await onComment(entity, msg);
    }, [entity, onComment, authenticated, navigate]);

    return (
        <Grid style={{ height: 'min-content' }}>
            <Grid.Column width={4}>
                <EntitySelector
                    onChange={(v) => {
                        setEntity(() => v);
                    }}
                />
            </Grid.Column>
            <Grid.Column width={12}>
                <InnerCommentInput
                    onSendComment={handleOnComment}
                />
            </Grid.Column>
        </Grid>
    );
}