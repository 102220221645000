import { useNavigate, useParams } from "react-router-dom";
import { ResponsiveContainer } from "../components/DefaultResponsiveContainer";
import { Button, Container, Grid, Header, Icon, Image, List, Placeholder, Segment, Tab } from "semantic-ui-react";
import { useCallback, useEffect, useState } from "react";
import { useCurrentBusinessPageContext } from "../storage/context/BusinessPageContext";
import { Post, PostPlaceholder } from "../components/post/Post";
import { useGeneralContext } from "../storage/context/GeneralContext";
import { ProductCard, ProductCardPlaceholder } from "../components/ProductCard";
import { followBusiness, getBusinessFeedPosts, getBusinessFollowers, getBusinessShop, unfollowBusiness } from "../api/business";
import { getChatMappingWithBusiness } from "../api/messaging";
import { handleRequiredAuth } from "../common/utils";

function BusinessProfileAbout() {
    const { currentBusinessProfile, loading } = useCurrentBusinessPageContext();
    if (loading) {
        return (
            <Segment basic>
                <Container>
                    <Placeholder>
                        <Placeholder.Paragraph>
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                        </Placeholder.Paragraph>
                    </Placeholder>
                </Container>
            </Segment>
        );
    }

    return (
        <Segment basic>
            <Container textAlign="left" style={{ backgroundColor: 'white', borderRadius: 10, padding: 10 }}>
                <Header>About</Header>
                <p>
                    {currentBusinessProfile.about}
                </p>
            </Container>
        </Segment>
    )
}

function BusinessProfilePosts() {
    const { currentBusinessProfile, loading } = useCurrentBusinessPageContext();
    const [posts, setPosts] = useState<any>([]);
    const [innerLoading, setLoading] = useState(false);

    useEffect(() => {
        const getPosts = async () => {
            setLoading(() => true);
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [data, _] = await getBusinessFeedPosts(currentBusinessProfile.store_id);
            setLoading(() => false);
            setPosts(data);
        }
        getPosts();
    }, [currentBusinessProfile]);

    if (loading || innerLoading) {
        return (
            <Segment basic className="center-segment">
                <Segment basic style={{ width: '100vw', maxWidth: 600 }}>
                    <List relaxed>
                        <List.Item>
                            <PostPlaceholder />
                        </List.Item>
                        <List.Item>
                            <PostPlaceholder />
                        </List.Item>
                        <List.Item>
                            <PostPlaceholder />
                        </List.Item>
                        <List.Item>
                            <PostPlaceholder />
                        </List.Item>
                    </List>
                </Segment>
            </Segment>
        );
    }


    return (
        <Segment basic className="center-segment">
            <Segment basic style={{ width: '90vw', maxWidth: 600 }} >
                <List relaxed>
                    {posts.map((postData: any) => {
                        return (
                            <List.Item key={postData.id}>
                                <Post
                                    key={postData.id}
                                    data={postData}
                                />
                            </List.Item>
                        )
                    })}
                </List>
            </Segment>
        </Segment>
    );
}

function BusinessProfileShop() {
    const { currentBusinessProfile, loading } = useCurrentBusinessPageContext();
    const [data, setData] = useState([]);
    const [innerLoading, setLoading] = useState(false);

    useEffect(() => {
        if (!currentBusinessProfile || !currentBusinessProfile.store_id) return
        const getData = async () => {
            setLoading(() => true);
            const data = await getBusinessShop(currentBusinessProfile.store_id);
            setData(data);
            setLoading(() => false);
        }
        getData();
    }, [setData, currentBusinessProfile])

    if (innerLoading || loading) {
        return (
            <Segment basic>
                <Grid stackable columns={2}>
                    <Grid.Column>
                        <ProductCardPlaceholder />
                    </Grid.Column>
                    <Grid.Column>
                        <ProductCardPlaceholder />
                    </Grid.Column>
                    <Grid.Column>
                        <ProductCardPlaceholder />
                    </Grid.Column>
                    <Grid.Column>
                        <ProductCardPlaceholder />
                    </Grid.Column>
                </Grid>
            </Segment>
        );
    }

    return (
        <Segment basic>
            <Grid stackable columns={2}>
                {data.map((data: any) => {
                    return (
                        <Grid.Column key={data.id}>
                            <ProductCard data={data} />
                        </Grid.Column>
                    )
                })}
            </Grid>
        </Segment>
    );
}
const panes = [
    {
        menuItem: 'Posts',
        render: () => (
            <Tab.Pane attached={false} basic style={{ backgroundColor: 'transparent' }} className="center-segment">
                <BusinessProfilePosts />
            </Tab.Pane>
        ),
    },
    {
        menuItem: 'Shop',
        render: () => (
            <Tab.Pane attached={false}>
                <BusinessProfileShop />
            </Tab.Pane>
        ),
    },
    {
        menuItem: 'About',
        render: () => (
            <Tab.Pane attached={false}>
                <BusinessProfileAbout />
            </Tab.Pane>
        ),
    },
]

function MessageButton() {
    const { authenticated, currentUser } = useGeneralContext();
    const { currentBusinessProfile } = useCurrentBusinessPageContext();
    const [chat_id, setChatId] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const abortController = new AbortController();
        const getChatMapping = async (signal: AbortSignal) => {
            if (!currentUser || !currentBusinessProfile) return;
            setLoading(() => true);
            try {
                const chat_id = await getChatMappingWithBusiness(currentBusinessProfile.store_id);
                if (!signal.aborted && chat_id) {
                    setChatId(chat_id);
                }
            } finally {
                setLoading(() => false);
            }
        }
        getChatMapping(abortController.signal);
        return () => {
            abortController.abort();
        }
    }, [currentUser, currentBusinessProfile, setChatId, setLoading]);

    const onClick = useCallback(() => {
        if (!authenticated) {
            return handleRequiredAuth(navigate, 'You need to be authenticated to message');
        }
        navigate(`/messages/${(chat_id) ? chat_id : 'newconv/' + currentBusinessProfile.store_id}`);
    }, [authenticated, navigate, chat_id, currentBusinessProfile]);

    if (loading) {
        return null;
    }

    return (
        <Button
            primary
            onClick={onClick}
            loading={loading}
            disabled={loading}
        >
            Message
        </Button>
    );
}

function FollowButton() {
    const [loading, setLoading] = useState(false);
    const { authenticated } = useGeneralContext();
    const { currentBusinessProfile } = useCurrentBusinessPageContext();
    const [isFollowing, setIsFollowing] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const getFollowers = async () => {
            setLoading(() => true);
            if (!currentBusinessProfile || !currentBusinessProfile.store_id) return;
            const data = await getBusinessFollowers(currentBusinessProfile.store_id);
            if (data.isFollowing) {
                setIsFollowing(() => true);
            }
            setLoading(() => false);
        }
        getFollowers();
    }, [currentBusinessProfile]);

    const onClick = useCallback(async () => {
        if (!authenticated) {
            return handleRequiredAuth(navigate, 'You need to be authenticated to follow');
        }
        if (!currentBusinessProfile || !currentBusinessProfile.store_id) return;
        setLoading(() => true);
        try {
            if (isFollowing) {
                await unfollowBusiness(currentBusinessProfile.store_id);
                setIsFollowing(() => false);
            } else {
                await followBusiness(currentBusinessProfile.store_id);
                setIsFollowing(() => true);
            }
        } finally {
            setLoading(() => false);
        }
    }, [currentBusinessProfile, isFollowing, authenticated, setLoading, navigate]);

    return (
        <Button
            primary={!isFollowing}
            onClick={onClick}
            loading={loading}
            disabled={loading}
        >
            {isFollowing ? "Unfollow" : "Follow"}
        </Button>
    );
}

function BusinessProfileHeader() {
    const { currentBusinessProfile, loading } = useCurrentBusinessPageContext();

    if (loading) {
        return (
            <Container>
                <Grid textAlign="left" style={{ backgroundColor: 'white', borderRadius: 10, padding: 10 }}>
                    <Grid.Row style={{ height: 'auto' }}>
                        <Grid.Column style={{ width: "auto" }}>
                            <Placeholder>
                                <Placeholder.Image style={{ width: 200, height: 200 }} />
                            </Placeholder>
                        </Grid.Column>
                        <Grid.Column className="grid-column-bottom" style={{ width: "70%" }} textAlign="left">
                            <Placeholder>
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }

    return (
        <Container>
            <Grid
                textAlign="left"
                style={{ backgroundColor: 'white', borderRadius: 10, padding: 10 }}
            >
                <Grid.Row>
                    <Grid.Column
                        style={{ width: "auto" }}
                    >
                        <Image src={currentBusinessProfile.image} style={{ width: 100, height: 100 }} />
                    </Grid.Column>
                    <Grid.Column
                        className="grid-column-bottom" style={{ width: "auto" }} textAlign="left">
                        <Header as={"h1"} style={{ marginTop: 50 }}>{currentBusinessProfile.name}</Header>
                        <div style={{ display: "flex", flexDirection: 'row' }}>
                            <Icon name="users" size="large" />
                            <p style={{ marginLeft: 10 }}>{currentBusinessProfile.followers} followers</p>
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <p style={{ textAlign: 'left' }}>{currentBusinessProfile.small_description}</p>
                </Grid.Row>
                <Grid.Row>
                    <MessageButton />
                    <FollowButton />
                </Grid.Row>
            </Grid>
        </Container>
    );
}

function InnerBusinessPage() {
    const { loading } = useCurrentBusinessPageContext();
    return (
        <Segment basic>
            <BusinessProfileHeader />
            {loading ?
                null
                :
                <Container style={{ borderRadius: 10, paddingTop: 10 }}>
                    <Tab
                        menu={{ secondary: true, pointing: true }}
                        panes={panes}
                    />
                </Container>
            }
        </Segment>
    );
}

export function BusinessPage() {
    const { url_basename } = useParams();
    const { setCurrentBusinessProfileURLBasename, currentBusinessProfile, loading } = useCurrentBusinessPageContext();
    useEffect(() => {
        if (!url_basename) {
            return;
        }
        setCurrentBusinessProfileURLBasename(url_basename);
    }, [url_basename, setCurrentBusinessProfileURLBasename]);

    const renderedComponent = useCallback(() => {
        if (!currentBusinessProfile && !loading) {
            return (<p>The business doesn't exist</p>);
        }
        return (
            <InnerBusinessPage />
        );
    }, [currentBusinessProfile, loading]);

    return (
        <ResponsiveContainer>
            {renderedComponent()}
        </ResponsiveContainer>
    );
}