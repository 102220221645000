import { Dimmer, Loader, Segment } from "semantic-ui-react";
import { MessageInput } from "./MessageInput";
import { useCallback, useState } from "react";
import { SimpleErrorMessage } from "../ErrorMessages";
import { useNavigate, useParams } from "react-router-dom";
import { useGeneralContext } from "../../storage/context/GeneralContext";
import { MessageContent } from "../../models/models";
import { createChatMappingWihBusiness, sendAMessage } from "../../api/messaging";

export function NewChatHolder(){
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const {business_id} = useParams();
    const {currentUser} = useGeneralContext();
    const navigate = useNavigate();

    const onSendMessage = useCallback(async (message: string) => {
        if (!business_id || !currentUser) return;
        setLoading(() => true);
        try {
            const chat_id = await createChatMappingWihBusiness(business_id);
            const data: MessageContent = {
                chat_id,
                message: {
                    text: message
                },
                sender: {
                    id: currentUser.uid,
                    type: "user"
                }
            }
            sendAMessage(data)
                .then(() => {
                    setLoading(() => false);
                    navigate(`/messages/${chat_id}`);
                })
                .catch((err) => {
                    setError((err as Error).message);
                    setLoading(() => false);
                });
        } finally{
            setLoading(() => false);
        }
    }, [business_id, setLoading, setError, currentUser, navigate]);

    if (loading) {
        return (
            <Segment basic style={{backgroundColor: 'white', borderRadius: 10}}>
                <Dimmer active>
                    <Loader>Loading</Loader>
                </Dimmer>
            </Segment>
        )
    }

    return (
        <Segment 
            basic 
            style={{
                backgroundColor: 'white', 
                borderRadius: 10, 
                height: '80vh', 
                overflowY: 'auto', 
                display: 'flex',
             flexDirection: 'column-reverse'
            }}>
            <MessageInput
                onSendMessage={onSendMessage}
                onError={() => {}}
                />
            {error ? 
                <SimpleErrorMessage 
                    error={error} 
                    hint="Make sure you are connected to the internet and retry later." 
                />
                : null
            }
        </Segment>
    )
}