import { useEffect, useState } from "react";
import { Divider, Header, Placeholder, Segment } from "semantic-ui-react";
import { ChatDisplay } from "./ChatDisplay";
import { getMyConversations } from "../../api/messaging";

export function ChatPlaceholder({number}:{number?: number}){
    const rows = [];
    for (let i = 0; i < (number || 5); i++) {
        rows.push(
            <Placeholder.Header key={i} image>
                <Placeholder.Line />
                <Placeholder.Line />
            </Placeholder.Header>
        )
    }
    return (
        <Placeholder>
            {rows}
        </Placeholder>
    )
}

export function InboxHolder(){
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getData = async () => {
            setLoading(() => true);
            setData(await getMyConversations());   
            setLoading(() => false);
        }
        getData();
    }, []);

    const chats = data.map((item: any, index) => {
        return (
            <ChatDisplay key={index} data={item} />
        )
    })
    
    return (
        <Segment basic style={{backgroundColor: 'white', borderRadius: 10, height: '80vh', overflowY: 'auto'}}>
            <Header textAlign="left" as={'h1'}>Chat</Header>
            <Divider />
            {loading ?
            <ChatPlaceholder /> : 
            chats}
            {data.length === 0 && !loading ? <p>No chats</p> : null}    
        </Segment>
    )
}