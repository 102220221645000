import { ReactNode, memo, useState } from "react";
import { ResponsiveContainer } from "../components/DefaultResponsiveContainer";
import { Header, Icon, Accordion, Container, Segment } from "semantic-ui-react";

interface FAQItemData {
    header: string;
    content: ReactNode;
    active: boolean;
    index: number;
    onSelect: () => void;
}

const FAQItem = ({ header, content, active, index, onSelect }: FAQItemData) => {
    return (
        <div>
            <Accordion.Title
                active={active}
                index={index}
                onClick={onSelect}
            >
                <Header
                    as={"h3"}
                >
                    <Icon name="dropdown" />
                    {header}
                </Header>
            </Accordion.Title>
            <Accordion.Content active={active}>
                <Segment basic>
                    <Container>
                        {content}
                    </Container>
                </Segment>
            </Accordion.Content>
        </div>
    );
}

const InnerFAQPage = () => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const handleClick = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqData = [
        {
            header: "How do I register a business?",
            content: (
                <div style={{ margin: 'auto' }}>
                    <ol style={{ textAlign: 'left' }}>
                        <li>Download the Chaguoo mobile app (iOS, Android).</li>
                        <li>Sign in or Sign up in the mobile app.</li>
                        <li>Click on the "Profile" button at the bottom of the screen.</li>
                        <li>Under "Business Management," click on the "Register A Business" button.</li>
                        <li>Login to your account once again.</li>
                        <li>Fill in the form and submit.</li>
                    </ol>
                    <p style={{ textAlign: 'left' }}>
                        We will reach out to you as quickly as possible. If we don't get back to you within 5 weekdays, <a href="https://chaguoo.com/support">contact us</a>.
                    </p>
                </div>
            )
        },
        {
            header: "How do I post content on behalf of the business?",
            content: (
                <div style={{ margin: 'auto' }}>
                    <p style={{ textAlign: 'left' }}>
                        In order to post on behalf of the business, you need to register the business first.
                    </p>
                    <ol style={{ textAlign: 'left' }}>
                        <li>Go to the <a href="https://business.chaguoo.com">business panel</a>.</li>
                        <li>Login with the account that you used to create the business.</li>
                        <li>Once logged in, you are redirected to the home page, where you'll see a card with the business's name.</li>
                        <li>Click on the card, under the "Posts" tab, click "Add New Post" and submit.</li>
                        <li>Fill in the pop-up form containing post content.</li>
                        <li>Click on the "Upload" button.</li>
                    </ol>
                    <p style={{ textAlign: 'left' }}>
                        The post can be seen on the mobile app. You can modify the post under the same tab by clicking on the post in the table. If you don't see the post, please <a href="https://chaguoo.com/support">contact us</a>.
                    </p>
                </div>
            )
        }
        
    ];

    return (
        <ResponsiveContainer>
            <Header as="h1" style={{ textAlign: "center", marginTop: "2em" }}>
                FAQ
            </Header>
            <p>
                If you don't find the answer to your question here, please contact us using the link in the header.
            </p>
            <Accordion fluid styled>
                {faqData.map((faq, index) => (
                    <FAQItem
                        index={index}
                        key={index + "_faq_index"}
                        onSelect={() => handleClick(index)}
                        active={activeIndex === index}
                        header={faq.header}
                        content={faq.content}
                    />
                ))}
            </Accordion>
        </ResponsiveContainer>
    );
};

export const FAQPage = memo(InnerFAQPage);