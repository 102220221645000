import { Divider, Message, Segment } from "semantic-ui-react";

export function SimpleErrorMessage({style, error, hint}: {style?: any, error: string, hint?: string}){
    return (
        <Segment basic style={style}>
            <Message negative>
                <Message.Header>
                    Sorry, seems like an error occured !
                </Message.Header>
                <Divider/>
                <p>{error}</p>
                <Divider/>
                <p>
                    {hint ? hint: ""} If the error continues please <a href="/support">contact us</a>
                </p>
            </Message>
        </Segment>
    )
}