import { Grid, Segment } from "semantic-ui-react";
import { ResponsiveContainer } from "../components/DefaultResponsiveContainer";
import { Helmet } from "react-helmet-async";
import { FeedScrollView } from "../components/FeedScrollView";
import { SideBarNavigation } from "../components/SideBarNavigation";
import { Media } from "../storage/Media";
import { AdsBarNavigation } from "../components/AdsBarNavigation";
import { HomeFeedContextProvider } from "../storage/context/HomeFeedContext";
import { useRef } from "react";

function MobileVersion(){
    const ref = useRef();

    return (
        <Media at="mobile">
            <Segment style={{backgroundColor: "#f5f5f5"}} className="center-segment">
                <FeedScrollView innerRef={ref} width={"16"}/>
            </Segment>
        </Media>
    )
}

function TabletVersion(){

    return (
        <Media at="tablet">
            <Grid columns={2} style={{backgroundColor: "#f5f5f5"}}>
                <Grid.Row>
                    <Grid.Column floated="left" width={"4"} style={{padding: 30}}>
                        <SideBarNavigation />
                        <AdsBarNavigation textAlign="left"/>
                    </Grid.Column>
                    <Grid.Column width={"12"}>
                        <FeedScrollView />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Media>
    );
}

function DesktopVersion(){

    return(
        <Media greaterThan="tablet">
            <Grid columns={3} style={{backgroundColor: "#f5f5f5"}}>
                <Grid.Row stretched>
                    <Grid.Column floated="right" width={"3"} style={{padding: 30}}>
                        <SideBarNavigation />
                    </Grid.Column>
                    <Grid.Column width={"10"} style={{padding: 30}}>
                        <FeedScrollView />
                    </Grid.Column>
                    <Grid.Column floated="left" width={"3"} style={{padding: 30}}>
                        <AdsBarNavigation />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Media>
    )
}

export default function HomePage(){
    return (
        <ResponsiveContainer>
            <Helmet> 
               <title>Chaguoo</title>
               <meta name="description" content="A Rwandan online marketplace. Buy & Sell online effortlessly." />
               <meta name="keywords" content="Chaguoo, Chaguo, Chaguo Rwanda, Rwanda Businesses, Shopping, African E-commerce, businesses Directory, Rwandan Businesses, Chaguo Business, Chaguo Africa, Chaguoo Africa, Rwanda Chaguoo, Chaguoo Rwanda" />
               <meta property="og:type" content="website" />
            </Helmet>
            <HomeFeedContextProvider>
                <MobileVersion />
                <TabletVersion />
                <DesktopVersion />
            </HomeFeedContextProvider>
        </ResponsiveContainer>
    );
}