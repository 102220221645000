import { memo } from "react";
import { Container, Grid, Header, List, Segment } from "semantic-ui-react";

function InnerFooter(){
    return (
        <Segment inverted vertical style={{ padding: '5em 0em' }}>
            <Container>
                <Grid divided inverted stackable>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Header inverted as='h4' content='Quick Links' />
                        <List link inverted>
                            <List.Item as='a' href='/about'>About</List.Item>
                            <List.Item as='a' href='/policy'>Policy</List.Item>
                            <List.Item as='a' href='/support'>Contact</List.Item>
                            <List.Item as='a' onClick={() => window.location.href="https://account.chaguoo.com/"}>Account management</List.Item>
                            <List.Item as='a' onClick={() => window.location.href="https://business.chaguoo.com/"}>Business Panel</List.Item>
                        </List>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Header inverted as='h4' content='Tutorial' />
                        <List link inverted>
                            <List.Item as='a' href='/faq'>Frequent Asked Questions</List.Item>
                        </List>
                    </Grid.Column>
                    <Grid.Column width={7}>
                        <p style={{color: '#fff'}}>
                            Copyright © 2024 Chaguoo.
                        </p>
                        {/*<Button 
                            onClick={() => window.location.href="https://www.linkedin.com/company/chaguoo"}
                            color="blue">
                            <Icon name='linkedin'/>LinkedIn
                        </Button>*/}
                    </Grid.Column>
                </Grid.Row>
                </Grid>
            </Container>
        </Segment>
    );
}

export const Footer = memo(InnerFooter);