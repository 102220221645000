import { getAnalytics } from "firebase/analytics";
import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from "firebase/functions";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { getMessaging, isSupported } from "firebase/messaging";

const firebaseConfig = {
    apiKey: process.env["REACT_APP_FIREBASE_apiKey"],
    authDomain: process.env["REACT_APP_FIREBASE_authDomain"],
    projectId: process.env["REACT_APP_FIREBASE_projectId"],
    storageBucket: process.env["REACT_APP_FIREBASE_storageBucket"],
    messagingSenderId: process.env["REACT_APP_FIREBASE_messagingSenderId"],
    appId: process.env["REACT_APP_FIREBASE_appId"],
    measurementId: process.env["REACT_APP_FIREBASE_measurementId"]
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
const storage = getStorage(app);
auth.useDeviceLanguage();
export const db = getFirestore(app);
export const functions = getFunctions(app);
export const messaging = async () => await isSupported() &&  getMessaging(app);

export async function uploadFileToStorage(file: File, fullfileName: string){
    const uploadRef = await uploadBytes(ref(storage, `${fullfileName}`), file);
    return getDownloadURL(uploadRef.ref);
}