import { ReactNode, useState } from 'react';
import { Configure, Index, useHits, useSearchBox } from 'react-instantsearch';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Icon } from 'semantic-ui-react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch';

const searchClient = algoliasearch(
    "FWPARR2K5H",
    "6345b793828b1a0a45b56660e6fbb685"
);

export function AlgoliaWrapper({children, index}: {children: ReactNode, index?: string}){
    return (
        <InstantSearch 
            indexName={index}
            searchClient={searchClient} 
        >
            {children}
        </InstantSearch>
    )
}

export function SearchGeneralComponent({children}: any){
    return (
        <Index indexName={(window.location.pathname === "/search/products") ? "products" : "shops"}>
            {children}  
        </Index>
    )
}

function returnBusinessHitProps(data: any, navigate: any){
    return {
        key: data.store_id,
        text: data.store_name,
        value: data.store_id,
        style: {},
        onClick: () => navigate(`/redictToBusinessFromId/${data.store_id}`)
    }
}

function returnProductHitProps(data: any, navigate: any){
    return {
        key: data.product_id,
        text: data.product_name,
        value: data.product_id,
        style: {},
        onClick: () => navigate(`/product/${data.product_id}`)
    }
}

function returnRedirectAllProps(query: string, navigate: any){
    const baseurl = window.location.pathname === "/search/products" ? "/search/products" : "/search/businesses";
    return {
        key: 'all',
        text: 'See All results' + (query ? `:  ${query}` : ''),
        value: query,
        style: {color: 'blue', fontWeight: 'bold', backgroundColor: 'lightgrey', textAlign: 'center'},
        onClick: () => navigate(`${baseurl}?q=${encodeURIComponent(query)}`)
    }

}
function returnHitProps(data: any, navigate: any){
    if (window.location.pathname === "/search/products") {
        return returnProductHitProps(data, navigate);
    }
    return returnBusinessHitProps(data, navigate);
}

function SearchBoxOutsideIndex({ ...props}){
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState("");

    const queryParameters = new URLSearchParams(window.location.search);
    const q = queryParameters.get("q");

    return (
        <Dropdown
            style={{marginLeft: 10, marginRight: 10}}
            clearable
            fluid
            search
            icon={
                <Icon 
                    name='search'
                    onClick={() => {
                        navigate(`${window.location.pathname}?q=${encodeURIComponent(searchValue)}`);
                    }}
                />
            }
            options={[]}
            defaultSearchQuery={q || ''}
            noResultsMessage={null}
            selection
            additionLabel={"Search :"}
            placeholder='Search ...'
            onKeyDown={(e: any) => e.key === 'Enter' && navigate(`${window.location.pathname}?q=${encodeURIComponent(searchValue)}`)}
            onSearchChange={(e: any) => setSearchValue(e.target.value)}
        />
    )
}

function CustomSearchBox({ ...props}) {
    const { refine, query } = useSearchBox(props);
    const { hits } = useHits(props);
    const navigate = useNavigate();

    const display:any[] = hits.map((item: any) => returnHitProps(item, navigate))
        .concat([returnRedirectAllProps(query, navigate)]);

    return (
        <Dropdown
            style={{marginLeft: 10, marginRight: 10}}
            clearable
            fluid
            icon={
                <Icon 
                    name='search'
                    onClick={() => {
                        navigate(`/search/businesses?q=${encodeURIComponent(query)}`);
                    }}
                />
            }
            search
            selection
            searchQuery={query}
            allowAdditions={display.length === 0}
            additionLabel={"Search :"}
            onAddItem={(e: any) => refine(e.target.value)}
            placeholder='Search ...'
            options={display}
            onSearchChange={(e: any) => refine(e.target.value)}
        />
    )
}

export function SearchComponent(){
    if (window.location.pathname.startsWith("/search/")){
        return (
            <SearchBoxOutsideIndex />
        );
    }

    return (
        <AlgoliaWrapper index="shops">
            <Configure hitsPerPage={6} />
            <CustomSearchBox />
        </AlgoliaWrapper>
    );
}