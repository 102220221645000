import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import GeneralContextProvider from './storage/context/GeneralContext';
import { ErrorFallback } from './components/ErrorFallback';
import * as Sentry from "@sentry/react";
import { BrowserRouter as Router } from "react-router-dom";
import { MediaContextProvider } from './storage/Media';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

Sentry.init({
    dsn: "https://6c661d69c58e717d1eef12b0701bf9f4@o4507056602873856.ingest.us.sentry.io/4507114335371264",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.3,
    replaysOnErrorSampleRate: 1.0,
    enabled: process.env.NODE_ENV !== 'development',
});

root.render(
    <React.StrictMode>
        <Sentry.ErrorBoundary
            fallback={ErrorFallback}
        >
            <MediaContextProvider>
                <GeneralContextProvider>
                    <HelmetProvider>
                        <Router>
                            <App />
                        </Router>
                    </HelmetProvider>
                </GeneralContextProvider>
            </MediaContextProvider>
        </Sentry.ErrorBoundary>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
