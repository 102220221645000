import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, List, Placeholder, Segment } from "semantic-ui-react";
import { useGeneralContext } from "../../storage/context/GeneralContext";
import { lightChocolate } from "../../common/theme";
import { SimpleErrorMessage } from "../ErrorMessages";
import { MessageContent } from "../../models/models";
import { MessageInput } from "./MessageInput";
import { getConversationMessages, sendAMessage } from "../../api/messaging";

const styleMyMessages = {
    textAlign: "right",
    backgroundColor: lightChocolate,
    borderRadius: 4,
    position: "relative",
    right: '-20%',
    padding: 10,
    width: "80%",
    height: "fit-content"
}

const styleOtherMessages = {
    textAlign: "left",
    backgroundColor: "lightgrey",
    borderRadius: 4,
    padding: 10,
    width: "80%",
    height: "fit-content"
}

function ChatMessage({ data }: any) {
    const { currentUser } = useGeneralContext();
    return (
        <List.Item className="chat-messages-right" style={{ width: "100%" }}>
            <List.Content className="chat-messages-right" style={(data.sender.id === currentUser?.uid) ? { ...styleMyMessages } : { ...styleOtherMessages }}>
                {data.message.text}
            </List.Content>
        </List.Item>
    )
}


export function ChatHolder() {
    const { chat_id } = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const { currentUser } = useGeneralContext();

    const getData = useCallback(async () => {
        if (!chat_id) return;
        setLoading(() => true);
        try {
            setData(await getConversationMessages(chat_id));
        } catch (err) {
            setError(() => (err as Error).message);
        }
        finally {
            setLoading(() => false);
        }
    }, [setData, chat_id, setLoading]);

    useEffect(() => {
        getData();
    }, [chat_id, getData]);

    const onSendMessage = useCallback(async (message: string) => {
        if (!chat_id || !message || !currentUser) return;
        setLoading(() => true);
        const data: MessageContent = {
            chat_id,
            message: {
                text: message
            },
            sender: {
                id: currentUser.uid,
                type: "user"
            }
        }
        sendAMessage(data)
            .then(() => {
                setLoading(() => false);
                getData();
            })
            .catch((err) => {
                setError((err as Error).message);
                setLoading(() => false);
            });

    }, [chat_id, currentUser, setLoading, setError, getData]);

    if (loading) {
        return (
            <Segment basic style={{ backgroundColor: 'white', borderRadius: 10, minHeight: 'fit-content' }}>
                <Placeholder style={{ width: '100%', height: '100%' }}>
                    <Placeholder.Line length="full" />
                    <Placeholder.Line length="full" />
                    <Placeholder.Line />
                    <Placeholder.Line length="full" />
                </Placeholder>
            </Segment>
        );
    }
    return (
        <Segment basic style={{ backgroundColor: 'white', borderRadius: 10, height: '85vh', display: 'flex', flexDirection: 'column-reverse', overflowY: 'hidden' }}>
            <MessageInput
                onSendMessage={onSendMessage}
                onError={(err: string) => setError(err)}
            />
            {error ?
                <SimpleErrorMessage
                    error={error}
                    hint="Make sure you are connected to the internet and retry later."
                />
                : null
            }
            <Container style={{ width: 0, marginTop: 20 }} />
            <List style={{ height: 'auto', overflowY: 'auto', paddingLeft: 4, paddingRight: 4 }}>
                {data.map((item: any, index) => (
                    <ChatMessage key={index} data={item} />
                ))}
            </List>
        </Segment>
    );
}