import { httpsCallable } from "@firebase/functions";
import { functions } from "./firebase";

export async function getAllProducts() {
    const fun = httpsCallable(functions, "getAllProducts");
    const resp: any = (await fun()).data;
    if (resp.status !== "success") return undefined;
    return resp.data;
}


export async function getProductFullInfo(product_id: string) {
    const fun = httpsCallable(functions, "getProductFullInfo");
    const resp: any = (await fun({ product_id: product_id })).data;
    if (resp.status !== "success") return undefined;
    return resp.data;
}

