import { Container, Dropdown, Form, Grid, Header, Segment } from "semantic-ui-react";
import { ResponsiveContainer } from "../components/DefaultResponsiveContainer";
import { Footer } from "../components/Footer";
import { useEffect, useState } from "react";
import { ProductCard, ProductCardPlaceholder } from "../components/ProductCard";
import { getAllProducts } from "../api/products";
import { FilterInfos, MarketplaceContextProvider, useMarketplaceContext } from "../storage/context/MarkeplaceContext";
import { CurrenciesOptions } from "../common/constant";
import { ProductData } from "../models/models";
import { Media } from "../storage/Media";
import _ from 'lodash';

function Filter() {
    const { filters, updateFilters } = useMarketplaceContext();
    return (
        <Media greaterThan="mobile">
            <Segment vertical basic style={{ padding: 10, width: "100%" }} textAlign="left">
                <Form style={{ width: '100%' }}>
                    <Header as="h1">Filter</Header>
                    <Form.Field>
                        <label>Price</label>
                        <Form.Group >
                            <Form.Input
                                type="number"
                                width={8}
                                min={0}
                                value={filters.minPrice}
                                onChange={(e, { value }) => {
                                    if (value) {
                                        const price = parseInt(value);
                                        if (!isNaN(price)) {
                                            updateFilters("minPrice", price);
                                        } else {
                                            updateFilters("minPrice", undefined);
                                        }
                                    } else {
                                        updateFilters("minPrice", undefined);
                                    }
                                }}
                                placeholder="Min Price"
                            />
                            <Form.Input
                                type="number"
                                width={8}
                                value={filters.maxPrice}
                                onChange={(e, { value }) => {
                                    if (value) {
                                        const price = parseInt(value);
                                        if (!isNaN(price)) {
                                            updateFilters("maxPrice", price);
                                        } else {
                                            updateFilters("maxPrice", undefined);
                                        }
                                    } else {
                                        updateFilters("maxPrice", undefined);
                                    }
                                }}
                                placeholder="Max Price"
                            />
                        </Form.Group>
                    </Form.Field>
                    <Form.Field>
                        <label>Currency</label>
                        <Dropdown
                            placeholder="Select Currency"
                            selection
                            options={CurrenciesOptions}
                            value={filters.currency}
                            onChange={(_, { value }) => {
                                if (value) {
                                    updateFilters("currency", value as string);
                                }
                            }}
                        />
                    </Form.Field>
                </Form>
            </Segment>
        </Media>
    );
}


function filterProducts(data: ProductData[], filters: FilterInfos) {
    let filteredData = data;
    if (filters.currency) {
        filteredData = filteredData.filter((product) => product.price.currency === filters.currency);
    }
    if (filters.minPrice) {
        filteredData = filteredData.filter((product) => product.price && product.price.amount >= (filters.minPrice || 0));
    }
    if (filters.maxPrice) {
        filteredData = filteredData.filter((product) => product.price && product.price.amount <= (filters.maxPrice || Infinity));
    }
    return filteredData;
}

function MarketplaceDisplay() {
    const [data, setData] = useState<ProductData[]>([]);
    const [filteredData, setFilteredData] = useState<ProductData[]>([]);
    const [loading, setLoading] = useState(false);

    const { filters } = useMarketplaceContext();

    useEffect(() => {
        const abortController = new AbortController();
        const fetchData = async () => {
            setLoading(() => true);
            try {
                const fetched = await getAllProducts();
                if (abortController.signal.aborted) return;
                setData(fetched);
                setFilteredData(fetched);
            } finally {
                if (abortController.signal.aborted) return;
                setLoading(() => false);
            }
        }
        fetchData();
        return () => {
            abortController.abort();
        }
    }, [setData]);

    useEffect(() => {
        const abortController = new AbortController();
        const filtered = filterProducts(data, filters);
        if (abortController.signal.aborted) return;
        setFilteredData(filtered);
        return () => {
            abortController.abort();
        }
    }, [data, filters]);

    if (loading) {

        return (
            <div>
                <Segment basic>
                    <Header as="h3">Products</Header>
                    <Container>
                        <Grid relaxed stackable>
                            {_.times(10, (i) => (
                                <Grid.Column key={i} mobile={8} tablet={8} computer={4}>
                                    <ProductCardPlaceholder />
                                </Grid.Column>
                            ))}
                        </Grid>
                    </Container>
                </Segment>
                <Segment basic>
                    <Header as="h3">Services</Header>
                    <Container>
                        <Grid relaxed stackable>
                            {_.times(10, (i) => (
                                <Grid.Column key={i} mobile={8} tablet={8} computer={4}>
                                    <ProductCardPlaceholder />
                                </Grid.Column>
                            ))}
                        </Grid>
                    </Container>
                </Segment>
                <Footer />
            </div>
        );
    }

    return (
        <div>
            <Segment basic>
                <Header as="h1">Products</Header>
                <p style={{ textAlign: 'left' }}>Showing {filteredData.length} products, out of {data.length}</p>
                <Container>
                    <Grid relaxed stackable>
                        {filteredData.filter((product: ProductData) => product.type === "product").map((product: any) => (
                            <Grid.Column key={product.id} mobile={8} tablet={8} computer={4}>
                                <ProductCard data={product} />
                            </Grid.Column>
                        ))}
                    </Grid>
                </Container>
            </Segment>
            <Segment basic>
                <Header as="h1">Services</Header>
                <Container>
                    <Grid relaxed stackable>
                        {filteredData.filter((product: ProductData) => product.type === "service").map((product: any) => (
                            <Grid.Column key={product.id} mobile={8} tablet={8} computer={4}>
                                <ProductCard data={product} />
                            </Grid.Column>
                        ))}
                    </Grid>
                </Container>
            </Segment>
        </div>
    );
}

export function MarketplacePage() {
    return (
        <ResponsiveContainer>
            <MarketplaceContextProvider>
                <Media at="mobile">
                    <Grid style={{ marginTop: 10 }} className="full-height">
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Header as="h1">Marketplace</Header>
                                <MarketplaceDisplay />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Media>
                <Media greaterThan="mobile">
                    <Grid style={{ marginTop: 10 }} className="full-height">
                        <Grid.Row>
                            <Grid.Column width={4} style={{ overflowX: 'auto' }}>
                                <Filter />
                            </Grid.Column>
                            <Grid.Column width={12}>
                                <Header as="h1">Marketplace</Header>
                                <MarketplaceDisplay />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Media>
                <Footer />
            </MarketplaceContextProvider>
        </ResponsiveContainer>
    );
}