import { Card, Image, Placeholder } from "semantic-ui-react";
import { truncateText } from "../common/utils";
import MediaSlider from "./MediaSlider";
import missing_image from '../images/white_image.png';
import { useNavigate } from "react-router-dom";

export function ProductCardPlaceholder() {
    return (
        <Card>
            <Card.Content>
                <Placeholder>
                    <Placeholder.Image square />
                    <Placeholder.Line length='full' />
                    <Placeholder.Line length='full' />
                </Placeholder>
            </Card.Content>
        </Card>
    );
}

export function ProductCard({ data, hideDescription }: any) {
    const formatter = new Intl.NumberFormat('en-US', { style: "currency", currency: data.price.currency });
    const navigate = useNavigate();
    return (
        <Card
            onClick={() => navigate(`/product/${data.id}`)}
        >
            {(data.media) ?
                <MediaSlider cover hideDotGroup remoteFiles={data.media} /> :
                <Image src={missing_image} />
            }
            <Card.Content>
                <Card.Header>{data.product_name}</Card.Header>
                <Card.Meta>
                    {formatter.format(data.price.amount)}
                </Card.Meta>
                {(hideDescription) ?
                    null :
                    <Card.Description style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {truncateText(data.description, 70)}
                    </Card.Description>}
            </Card.Content>
        </Card>
    )
}