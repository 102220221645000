import { Container, Grid, Header, Placeholder, Segment } from "semantic-ui-react";
import { ResponsiveContainer } from "../components/DefaultResponsiveContainer";
import { Footer } from "../components/Footer";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MediaSlider from "../components/MediaSlider";
import { ProductData } from "../models/models";
import { getProductFullInfo } from "../api/products";
import { Media } from "../storage/Media";

function InnerProductPagePlaceholder() {
    return (
        <Grid
            stackable
            style={{
                width: '84%',
                height: '80vh',
                marginLeft: '8%',
                marginTop: '1%',
                marginRight: '8%',
                marginBottom: '1%',
                backgroundColor: 'white',
            }}
        >
            <Grid.Column style={{ width: '50%', height: 'fit-content' }}>
                <Placeholder style={{ width: '100%', height: '100%' }}>
                    <Placeholder.Image />
                </Placeholder>
            </Grid.Column>
            <Grid.Column style={{ width: '50%' }} textAlign="left">
                <Placeholder>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line length="full" />
                    <Placeholder.Line length="full" />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line length="full" />
                </Placeholder>
            </Grid.Column>
        </Grid>
    );
}

function MobileProductPage({ data, product_id }: { data: ProductData | undefined, product_id: string | undefined }) {

    const formatter = data ? new Intl.NumberFormat('en-US', { style: "currency", currency: data.price.currency }) : undefined;

    return (
        <Media at="mobile">
            <Segment basic className="center-segment">
                <Grid
                    stackable
                    style={{
                        width: '84%',
                        marginLeft: '8%',
                        marginTop: '1%',
                        marginRight: '8%',
                        marginBottom: '1%',
                        backgroundColor: 'white',
                    }}
                >
                    <Grid.Column style={{ width: '50%', overflowY: 'auto' }} stretched>
                        <Container style={{ width: '80%', overflowY: 'auto' }}>
                            {data
                                ? <MediaSlider aspect remoteFiles={data.media} />
                                : null}
                        </Container>
                    </Grid.Column>
                    <Grid.Column style={{ width: '50%' }} textAlign="left">
                        {data ?
                            <>
                                <a href={`/redictToBusinessFromId/${data?.store_id}`}>Visit Shop</a>
                                <Header>{data?.product_name}</Header>
                                <p
                                    style={{
                                        maxWidth: '100%',
                                    }}
                                >{data?.description}
                                </p>
                                {data ? <p>{formatter?.format(data?.price.amount)}</p> : null}
                            </> :
                            null
                        }
                    </Grid.Column>
                </Grid>
            </Segment>
        </Media>
    );
}

function DesktopAndTabletProductPage({ data, product_id }: { data: ProductData | undefined, product_id: string | undefined }) {

    const formatter = data ? new Intl.NumberFormat('en-US', { style: "currency", currency: data.price.currency }) : undefined;

    return (
        <Media greaterThan="mobile">
            <Segment basic className="center-segment">
                <Grid
                    stackable
                    style={{
                        width: '84%',
                        height: '80vh',
                        marginLeft: '8%',
                        marginTop: '1%',
                        marginRight: '8%',
                        marginBottom: '1%',
                        backgroundColor: 'white',
                    }}
                >
                    <Grid.Column style={{ width: '50%', height: '90%', overflowY: 'auto' }} stretched>
                        <Container style={{ width: '80%', overflowY: 'auto' }}>
                            {data
                                ? <MediaSlider aspect remoteFiles={data.media} />
                                : null}
                        </Container>
                    </Grid.Column>
                    <Grid.Column style={{ width: '50%' }} textAlign="left">
                        {data ?
                            <>
                                <a href={`/redictToBusinessFromId/${data?.store_id}`}>Visit Shop</a>
                                <Header>{data?.product_name}</Header>
                                <p
                                    style={{
                                        maxWidth: '100%',
                                    }}
                                >{data?.description}
                                </p>
                                {data ? <p>{formatter?.format(data?.price.amount)}</p> : null}
                            </> :
                            null
                        }
                    </Grid.Column>
                </Grid>
            </Segment>
        </Media>
    );
}

export function ProductPage() {
    const [data, setData] = useState<ProductData | undefined>();
    const [loading, setLoading] = useState(false);
    const { product_id } = useParams();

    useEffect(() => {
        const abortController = new AbortController();
        const getData = async () => {
            if (!product_id) return;
            setLoading(() => true);
            if (abortController.signal.aborted) return;
            const fetched = await getProductFullInfo(product_id);
            if (abortController.signal.aborted) return;
            setLoading(() => false);
            if (abortController.signal.aborted) return;
            setData(fetched);
        }
        getData();
        return () => {
            abortController.abort();
        }
    }, [product_id, setData]);

    if (loading) {
        return (
            <InnerProductPagePlaceholder />
        );
    }

    return (
        <ResponsiveContainer>
            <MobileProductPage data={data} product_id={product_id} />
            <DesktopAndTabletProductPage data={data} product_id={product_id} />
            <Footer />
        </ResponsiveContainer>
    )
}