import { Helmet } from "react-helmet-async";

interface MetadataGeneratorProps {
    title: string;
    description?: string;
    image?: string;
}

export function MetadataGenerator(props: MetadataGeneratorProps) {
    return (
        <Helmet>
            {/* General Tags */}
            {props.title ? <meta property="og:title" content={props.title} /> : null}
            {props.description ? <meta property="og:description" content={props.description} /> : null}
            {props.image ? <meta property="og:image" content={props.image} /> : null}

            {/* Twitter Card Tags */}
            <meta name="twitter:card" content="summary_large_image" />
            {props.title ? <meta name="twitter:title" content={props.title} /> : null}
            {props.description ? <meta name="twitter:description" content={props.description} /> : null}
            {props.image ? <meta name="twitter:image" content={props.image} /> : null}
        </Helmet>
    );
}