export function generateLoremIpsum(paragraphs = 1) {
    const loremIpsumText = `Lorem ipsum dolor sit amet, consectetur adipisci
    ng elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut en
    im ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea comm
    odo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu 
    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
    officia deserunt mollit anim id est laborum.`;
    return Array(paragraphs).fill(loremIpsumText).join('\n\n');
};

export function truncateText(text: string, maxLength: number) {
    if (!text) {
        return '';
    }
    if (text.length > maxLength) {
        return  text.substr(0, maxLength) + '...';
    }
    return text;
}

export function openInNewTab(url: string){
    window.open(url, '_blank', 'noreferrer');
}

export function handleRequiredAuth(navigator: any, message: string) {
    const encodedMessage = encodeURIComponent(message);
    const newUrl = `/login?cause=authrequired&message=${encodedMessage}`;
    return navigator(newUrl);
}