import './App.css';
import { Routes, Route, useLocation } from "react-router-dom";
import 'semantic-ui-css/semantic.min.css';
import HomePage from './screens/HomePage';
import PolicycyPage from './screens/Privacy';
import SupportPage from './screens/SupportPage';
import { FAQPage } from './screens/FaqPage';
import AboutPage from './screens/AboutPage';
import { BusinessPage } from './screens/BusinessPage';
import { CurrentBusinessPageContextProvider } from './storage/context/BusinessPageContext';
import { LoginPage } from './screens/LoginPage';
import { MarketplacePage } from './screens/Marketplace';
import { PricingPage } from './screens/PricingPage';
import { SignUpPage } from './screens/SignupPage';
import { MessagesPage } from './screens/MessagesPage';
import { ProtectedPage, RedirectBusinessPageFromId } from './components/Redirectors';
import { ChatHolder } from './components/messaging/ChatHolder';
import { NewChatHolder } from './components/messaging/NewChatHolder';
import { SearchPage, ProductsSearchResultsHolder, BusinessesSearchResultsHolder } from './screens/SearchPage';
import { ProductPage } from './screens/ProductPage';
import { ProfileManagement } from './screens/ProfileManagement';
import DeleteAccountPage from './screens/AccountDeletePage';
import { PostModalScreen } from './screens/PostModalScreen';

function App() {
    const location = useLocation();
    const previousLocation = location.state?.previousLocation;

    return (
        <div className="App">
            <Routes location={previousLocation || location}>
                <Route
                    path='/'
                    element={<HomePage />}
                />
                <Route
                    path='/about'
                    element={<AboutPage />}
                />
                <Route
                    path='/company/:url_basename'
                    element={
                        <CurrentBusinessPageContextProvider>
                            <BusinessPage />
                        </CurrentBusinessPageContextProvider>
                    }
                />
                <Route
                    path='/deleteAccount'
                    element={
                        <ProtectedPage
                            page={<DeleteAccountPage />}
                        />
                    }
                />
                <Route
                    path='/faq'
                    element={<FAQPage />}
                />
                <Route
                    path="/login/:cause?"
                    element={<LoginPage />}
                />
                <Route
                    path="/marketplace"
                    element={<MarketplacePage />}
                />
                <Route
                    path="/messages"
                    element={
                        <ProtectedPage
                            page={<MessagesPage />}
                        />
                    }
                >
                    <Route path=':chat_id' element={<ChatHolder />} />
                    <Route path='newconv/:business_id' element={<NewChatHolder />} />
                </Route>
                <Route
                    path='/policy'
                    element={<PolicycyPage />}
                />
                <Route
                    path='/post/:post_id'
                    Component={PostModalScreen}
                />
                <Route
                    path="/pricing"
                    element={<PricingPage />}
                />
                <Route
                    path='/product/:product_id'
                    element={<ProductPage />}
                />
                <Route
                    path='/myprofile'
                    element={
                        <ProtectedPage
                            page={<ProfileManagement />}
                        />
                    }
                />
                <Route
                    path='/redictToBusinessFromId/:business_id'
                    element={
                        <RedirectBusinessPageFromId />
                    }
                />
                <Route
                    path='/signup'
                    element={<SignUpPage />}
                />
                <Route
                    path='/search'
                    element={<SearchPage />}
                >
                    <Route path='businesses/:q?' element={<BusinessesSearchResultsHolder />} />
                    <Route path='products/:q?' element={<ProductsSearchResultsHolder />} />
                </Route>
                <Route
                    path='/support'
                    element={<SupportPage />}
                />
                <Route
                    path="*"
                    element={<p>Sorry the page. Doesn't exist</p>}
                />
            </Routes>

            {previousLocation && (
                <Routes>
                    <Route path="/post/:post_id" element={<PostModalScreen />} />
                </Routes>
            )}
        </div>
    );
}

export default App;